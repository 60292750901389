import { Box, Radio, CircularProgress } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AlertDialog } from "~/components/Dialogs";
import { maskedAccountNumber } from "~/redux/helpers/maskedaccountnumber";
import { redeemRewards, fetchRewardsList } from "../../../redux/actions/redeem";
import { updateAccount } from "~/redux/actions/manage-account";
import { useDispatch, useSelector } from "react-redux";
import currency from "currency.js";

const RedeemRewards = ({
  oeid,
  portbu,
  accessToken,
  close,
  handleRedeemAmount,
}) => {
  const dispatch = useDispatch();
  const redeemData = useSelector((state) => state.redeem);

  const [open, setOpen] = useState(true);
  const [maskedNumber, setMaskedNumber] = useState([]);
  const [defaultPaymentId, setDefaultPaymentId] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [openEnrollmentPopup, setOpenEnrollmentPopup] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [totalRewards, setTotalRewards] = useState(0);
  const [redeemed, setRedeemed] = useState(false);
  const [isZelle, setIsZelle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(fetchRewardsList({ accessToken, portbu, oeid }));
  }, [dispatch, accessToken, portbu, oeid]);

  useEffect(() => {
    const fetchMaskedNumber = async () => {
      try {
        const response = await maskedAccountNumber({
          accessToken,
          portbu,
          oeid,
        });
        const maskedData = response.Data || [];
        setMaskedNumber(maskedData);

        const defaultPayment = maskedData.find(
          (m) => m.default === "1" || m.default === 1
        );
        setDefaultPaymentId(defaultPayment ? defaultPayment.paymentTypeId : 2);
      } catch (error) {
        handleError();
      } finally {
        setIsLoading(false);
      }
    };

    fetchMaskedNumber();
  }, [accessToken, portbu, oeid]);

  useEffect(() => {
    if (redeemData.redeem.list.length > 0) {
      const totalReward = redeemData.redeem.list[0].TotalRewards || 0;
      setTotalRewards(totalReward);
    }
  }, [redeemData.redeem.list]);

  const checkAliasEnrollment = async () => {
    let ZelleDetails = maskedNumber.find((q) => q.paymentTypeId === 3);
    let BankAccountDetails = maskedNumber.find((q) => q.paymentTypeId === 2);
    try {
      const response = await dispatch(
        updateAccount({
          accessToken,
          lRoutingNumber: (BankAccountDetails && BankAccountDetails.routing_no) || "",
          lAccountNumber: (BankAccountDetails && BankAccountDetails.account_no) || "",
          lConfirmAccountNumber: (ZelleDetails && ZelleDetails.confirm_acc_no) || "",
          lAccountType: (ZelleDetails && ZelleDetails.account_type),
          lClientID: 1,
          lStatus: 1,
          oeid,
          portbu,
          lEmail: (ZelleDetails && ZelleDetails.Email) || "",
          lMobile: (ZelleDetails && ZelleDetails.Mobile) || "",
          lZelleToken: (ZelleDetails && ZelleDetails.ZelleToken),
          lPaymentMethodId: defaultPaymentId || 0,
          lSelectedPaymentId: defaultPaymentId || 0,
        })
      );
      return response;
    } catch (error) {
      handleError();
    }
  };

  const initiateRedeem = () => {
    if (!maskedNumber.length) {
      return;
    }

    const rewardIds = redeemData.redeem.list
      .filter((item) => item.IsExpired === 0)
      .map((item) => item.RewardId);

    dispatch(
      redeemRewards({
        rewardIds: rewardIds.join(),
        accessToken,
        paymentMethodId: defaultPaymentId,
      })
    )
      .then((response) => {
        if (!response) {
          handleError();
          return;
        }
        if (typeof handleRedeemAmount === "function") {
          handleRedeemAmount(response);
        }else{
          handleError();
        }

        setOpen(false);
        setRedeemed(true);
        setBtnDisabled(false);
      })
      .catch(() => handleError());
  };

  const handleClickRedeem = () => {
    setBtnDisabled(true);
    if (selectedPaymentId === 3 || defaultPaymentId === 3) {
      checkAliasEnrollment()
        .then((response) => {
          if (response.Data.responseCode === 1) {
            handleOpenEnrollmentPopup();
          } else {
            initiateRedeem();
          }
        })
        .catch((error) => {
          console.error("Error during alias enrollment check:", error);
        });
    } else {
      initiateRedeem();
    }
  };

  const handleOpenEnrollmentPopup = () => {
    setOpenEnrollmentPopup(true);
  };

  const closeEnrollmentPopup = () => {
    setOpenEnrollmentPopup(false);
  };

  const returnEnrollmentPopup = (title, message) => (
    <AlertDialog
      onClose={closeEnrollmentPopup}
      title={title}
      message={message}
      confirmText="Dismiss"
      onConfirm={closeEnrollmentPopup}
    />
  );

  const handleError = () => {
    setErrorDialog(true);
  };

  const closeErrorDialog = () => {
    setErrorDialog(false);
  };

  const closeRedeemed = () => {
    setBtnDisabled(true);
    setRedeemed(false);
  };
  const handlePaymentMethodsChange = (paymentMethodId) => {
    debugger;
    setDefaultPaymentId(paymentMethodId);
  };
  const handleCloseRedeem = () => {
    setOpen(false);
    if (typeof close === "function") {
      close();
    } else {
      console.error("closeStatus is not a function");
    }
  };
  return (
    <>
      {open && (
        <AlertDialog
          clickOutsideToClose={false}
          redeemDisabled={!maskedNumber.length || defaultPaymentId === null}
          title="Redeem Your Reward"
          show={maskedNumber.length > 0}
          confirmText="Redeem"
          px={10}
          py={4}
          onConfirm={handleClickRedeem}
          onClose={handleCloseRedeem}
          btnDisabled={btnDisabled}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box className="redeem-amount">
                {currency(totalRewards, { formatWithSymbol: true }).format()}
              </Box>
              {maskedNumber.length > 0 ? (
                <Fragment>
                  <Box className="redeem-message">
                    I would like rewards to be deposited in
                  </Box>
                  {maskedNumber.map((paymentMethod) => (
                    <Box
                      className="redeem-message-radio"
                      key={paymentMethod.paymentTypeId}
                    >
                      <label>
                        <Radio
                          color="primary"
                          value={paymentMethod.paymentMethodName}
                          checked={
                            paymentMethod.paymentTypeId === defaultPaymentId
                          }
                          onChange={() =>
                            handlePaymentMethodsChange(
                              paymentMethod.paymentTypeId
                            )
                          }
                        />
                        {paymentMethod.paymentTypeId === 2 ? (
                          `${paymentMethod.paymentMethodName} ${paymentMethod.masked_AccountNumber}`
                        ) : (
                          <img
                            alt="zelle-registered"
                            style={{
                              height: "22px",
                              width: "85px",
                              objectFit: "cover",
                            }}
                            src={require("~/assets/images/zelle-icon-registered.jpg")}
                          />
                        )}
                      </label>
                    </Box>
                  ))}
                </Fragment>
              ) : (
                <Box className="redeem-message success">
                  To Redeem your Rewards you must first setup your account
                  details.
                </Box>
              )}
              <Link
                to={{
                  pathname: "/manage-account",
                  state: {
                    fromRedeem: true,
                    rewardsList: redeemData.redeem.list,
                  },
                }}
                className="alert-dialog-link"
              >
                Manage Account
              </Link>
            </Box>
          )}
        </AlertDialog>
      )}

      {redeemed && (
        <AlertDialog
          clickOutsideToClose={false}
          success={true}
          title=""
          confirmText="Continue"
          px={10}
          py={4}
          onConfirm={closeRedeemed}
          btnDisabled={btnDisabled}
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box className="redeem-amount">
              {currency(totalRewards, { formatWithSymbol: true }).format()}
            </Box>
            <Box
              className="redeem-message"
              color="primary.main"
              fontWeight={500}
            >
              Was successfully Submitted
            </Box>
            <Box className="redeem-message success" color="primary.main">
              {isZelle || defaultPaymentId === 3
                ? `You should receive your funds in a few minutes.`
                : `We're sending deposits to your bank account. This usually takes 2-3 business days. You can log back into CA$HIN and verify the transaction.`}
            </Box>
            <Link to="/payments" className="alert-dialog-link">
              Track your Payments
            </Link>
          </Box>
        </AlertDialog>
      )}

      {openEnrollmentPopup && returnEnrollmentPopup("", "Enrollment not done")}
      {errorDialog && (
        <AlertDialog
          title="OOPS!"
          message="Something went wrong, We're working on getting this fixed as soon as we can."
          onConfirm={closeErrorDialog}
        />
      )}
    </>
  );
};

export default RedeemRewards;
