

import React from "react";

const SessionTimedOut = () => {
    return (
        <div>
            <div>
                <div id="overlay" style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0 }}>
                    <div class="backdrop" style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, height: "100%", width: "100%", background: "black", zIndex: 9, opacity: 0.5 }}></div>
                    <div style={{ background: `url('../static/media/login-bg.b164f405.jpg')`, width: "50%", height: "100%", zIndex: 0, backgroundRepeat: "round", position: "absolute" }}>
                    </div><div class="card" style={{ padding: "10px 10px", display: "table", background: "white", position: "absolute", width: "48%", height: "100%", right: 0, margin: "50px auto", transition: "all 0.3s cubic-bezier(.25,.8,.25,1)" }}>
                        <h2 style={{ padding: 0, margin: 0 }}>ELAN</h2><h2 style={{ color: "grey" }}>Financial Services</h2> </div><div class="popup" style={{ position: "fixed", left: "50%", right: "50%", top: "50%", width: "400px", height: "170px", textAlign: "center", marginLeft: "-232px", marginTop: "-140px", background: "white", padding: "20px 30px", zIndex: "10" }}>
                         <h1>Session Timed Out !</h1>
                        <span style={{ color: "grey", fontSize: "18px" }}>
                        You have been logged out successfully.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SessionTimedOut;