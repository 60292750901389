import React, { useEffect, useRef, useState, memo } from "react";
import "./styles.scss";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import { getSalesTips } from "../../../redux/helpers/dashboard";

const SalesTip = ({ closeTooltip, openTooltip }) => {
  const [salesTips, setSalesTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    const fetchSalesTips = () => {
      getSalesTips({ featureKey: 1 }).then((res) => {
        if (res) {
          setSalesTips(res.data);
          setLoading(false);
        }
      });
    };
    fetchSalesTips();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (typeof openTooltip === "function") {
          openTooltip();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openTooltip]);

  return (
    <div ref={ref} className="profile-card" style={{ height: "213px" }}>
      <div className="card-header">
        <IconButton
          onClick={closeTooltip}
          style={{
            borderRadius: 0,
            width: "18px",
            height: "18px",
            backgroundColor: "white",
          }}
        >
          {/* {!loading ? <CloseIcon className="close-icon" /> : null} */}
          {!loading ? (
            <img
              src={require("~/assets/images/header/Salestip-close.svg")}
              alt=""
              className="close-icon"
            />
          ) : null}
        </IconButton>
      </div>
      <div className="card-content">
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            {salesTips.map((salesTip) => {
              return (
                <>
                   <div style={{ height: "56px" , background:"#F1F5F7", borderTopRightRadius:"13px"}}>
                    <img src={salesTip.IconURL} alt="Sales-Tip" />
                  </div>
                  <Typography
                    style={{
                      fontWeight: 700,
                      marginTop: "10px",
                      fontSize: "16px",
                      textAlign:"center"
                    }}
                  >
                    {salesTip.Heading}
                  </Typography>
                  <Typography
                    style={{
                      boxSizing: "border-box",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight:"22px",
                      textAlign:"center"
                    }}
                  >
                    {salesTip.SubHeading}
                  </Typography>
                </>
              );
            })}
          </>
        )}
      </div>
      <div className="card-arrow" />
    </div>
  );
};
export default memo(SalesTip);
