// QuestionComponent.js
import React, { useEffect, useState, memo, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyQuestionAndAnswers,
  storeBankerSuveryData,
  getBankerSurveyResponse,
} from "../../../redux/actions/survey";
import "./styles.scss";

const QuestionComponent = () => {
  const dispatch = useDispatch();
  const getQuestion = useSelector((state) => state.Survey_Reducer.Data);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionId, setQuestionId] = useState(0);
  const [calculatePercentage, setCalculatePercentage] = useState([]);
  const [message, setMessage] = useState(null);

  const { surveyResponse } = useSelector((state) => state.Survey_Reducer);

  useEffect(() => {
    const getItems = sessionStorage.getItem("questionPerc"); //not preferable
    const Val = JSON.parse(getItems);

    if (Val) {
      setCalculatePercentage(Val.Options);
      setSelectedOption(true);
    }
    if (
      Object.keys(getQuestion).length === 0 &&
      getQuestion.constructor === Object
    ) {
      dispatch(getSurveyQuestionAndAnswers());
    }
  }, []);

  useEffect(() => {
    if (getQuestion.Data) {
      const getQuestionObj = getQuestion.Data.Question;
      setQuestionId(getQuestionObj.Questionid);
      setQuestion(getQuestionObj.Questiontext);
      setOptions(getQuestion.Data.options);
    }
    if (surveyResponse) {
      setCalculatePercentage(surveyResponse.Options);
      setSelectedOption(true);
      sessionStorage.setItem("questionPerc", JSON.stringify(surveyResponse)); // not preferable
    }
  }, [getQuestion, surveyResponse]);

  const handleOptionClick = useCallback(async (optionId, questId) => {
    if (selectedOption === null) {
      try {
        setMessage("Thank you for participating in our survey!");
        setSelectedOption(true);
        dispatch(storeBankerSuveryData(optionId, questId));
        setTimeout(() => {
          dispatch(getBankerSurveyResponse(questionId));
        }, 2000);
      } catch (error) {
        setMessage(
          "There was an error submitting your response. Please try again."
        );
      }
    } else if (selectedOption === true) {
      setMessage("You have already filled the survey!");
    }
  });
  const calculateProgress = useCallback(
    (optionId) => {
      const res = calculatePercentage.find(
        (item) => item.Optionid === optionId
      );
      return res ? `${res.Percentage}%` : null;
    },
    [calculatePercentage]
  );

  return (
    <Box style={{ p: 2 }}>
      {message &&<Typography
        component="h6"
        style={{
          color: "#398245",
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "30px",
        }}
      >
        {message}
      </Typography>}
      <Typography
        style={{
          fontWeight: 600,
          fontSize: selectedOption === true ? "16px" : "20px",
        }}
      >
        {selectedOption === true
          ? "See how other employees have spent their rewards "
          : question}
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        direction="row"
        style={{ marginTop: "6px" }}
      >
        {options.map((option, index) => (
          <Grid item xs={6}>
            <Button
              tabIndex="0"
              className={selectedOption === null ? "Survey_Button" : ""}
              key={index}
              onClick={() => handleOptionClick(option.Optionid, questionId)}
              style={{
                border:
                  selectedOption !== null
                    ? "1px solid #E6E6E6"
                    : "1px solid #E6E6E6",
                color: selectedOption !== null ? "#084BB7" : "#000",
                position: "relative",
                width: "100%",
                height: "40px",
                justifyContent: "flex-start",
                textAlign: "left",
                padding: "10px",
                backgroundColor: selectedOption === null ? "#E6E6E6" : "#FFF",
                fontWeight: 600,
                fontSize: "16px",
                boxSizing: "border-box",
              }}
            >
              <div style={{ flex: 1 }}>{option.Optiontext}</div>
              {selectedOption !== null &&
                (calculateProgress(option.Optionid) === null ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      lineHeight: "27px",
                    }}
                  >
                    {calculateProgress(option.Optionid)}
                  </Typography>
                ))}

              {selectedOption !== null && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "100%",
                    width: `${calculateProgress(option.Optionid)}`,
                    backgroundColor: "rgb(90 92 95 / 20%)",
                  }}
                />
              )}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(QuestionComponent);
