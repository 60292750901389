import React, { Component } from "react";
import { Box, withStyles } from "@material-ui/core";

import { Link } from "react-router-dom";

const styles = (theme) => ({
  link: {
    color: "#000",
    fontSize: "11px",
    textDecoration: "underline",
    "&:last-child": {
      paddingLeft: "25px",
    },
    "&:hover": {
      opacity: "0.5",
    },
  },
  copyRight: {
    color: "#000",
    fontSize: "11px",
  },
  img: {
    paddingLeft: "5px",
  },
});

class FooterNav extends Component {
  render() {
    const { classes, isLoggedIn } = this.props;
    return (
      <Box display="flex">
        <Box flexGrow={1}>
          {isLoggedIn && (
            <Link to="/faq" className={classes.link}>
              {" "}
              <label style={{ padding: "10px", fontSize:"12px", fontWeight:450}}>FAQ</label>
            </Link>
          )}
          {isLoggedIn && (
            <Link to="/contact" className={classes.link}>
              <label style={{ padding: "10px", fontSize:"12px", fontWeight:450 }}>CONTACT</label>
            </Link>
          )}
        </Box>
        <Box className={classes.copyRight}>
          <Box display="flex" alignItems="center" style={{fontWeight:700, fontSize:"12px"}}>
            {" "}
            ©2020 Elan Financial Services Powered by{" "}
            <img
              alt="Incedo Inc."
              src={require("~/assets/images/incedo-logo-Color.png")}
              className={classes.img}
              style={{width:"68px", height:"18px"}}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(FooterNav);
