import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import SSNAlertComponent from "./SSNAlertComponent";
import RedeemRewards from "./RedeemRewards";
import { useSelector } from "react-redux";
import { checkW9FormExistance } from "../../../redux/helpers/redeem";
import "./styles.scss";

function RedeemButton({ currentBalance, handleRedeemAmount }) {
  const userSelector = useSelector((state) => state.user);
  const { oeid, portbu, accessToken } = userSelector.user.info;

  const [isW9Exist, setIsW9Exist] = useState(0);
  const [openSSNAlert, setOpenSSNAlert] = useState(false);
  const [openW9ExistAlert, setOpenW9ExistAlert] = useState(false);
  const [SSN, setSSN] = useState({ one: "", two: "", three: "", value: "" });

  useEffect(() => {
    const fetchW9Existence = async () => {
      try {
        const response = await checkW9FormExistance({
          PBU: portbu,
          OEID: oeid,
          accessToken,
        });

        const data = await response.Data.IsExists;
        setIsW9Exist(data);
      } catch (error) {
        console.error("Failed to fetch W9 existence:", error);
      }
    };

    fetchW9Existence();
  }, []);

  const handleOpenSSNAlert = () => {
    setOpenSSNAlert(true);
    setOpenW9ExistAlert(false);
    setSSN({ one: "", two: "", three: "", value: "" });
  };

  const handleClickRedeem = () => {
    if (isW9Exist == 0) {
      handleOpenSSNAlert();
    } else {
      setOpenW9ExistAlert(true);
    }
  };

  const handleClose = () => {
    setOpenW9ExistAlert(false);
  };

  const getSSNALert = (alertStatus) => {
    setOpenSSNAlert(alertStatus);
  };

  return (
    <div>
      {openSSNAlert && (
        <SSNAlertComponent
          openSSNAlert={getSSNALert}
          oeid={oeid}
          portbu={portbu}
          accessToken={accessToken}
          ssnIsExists={isW9Exist}
          totalRewards={currentBalance}
        />
      )}
      <Button
        variant="contained"
        className={currentBalance != 0 ? "redeemButton" : ""}
        onClick={handleClickRedeem}
        closeStatus={handleClose}
        disabled={currentBalance == 0 ? true : false}
        style={{
          marginLeft: "auto",
          color: "#FFFFFF",
          border: currentBalance == 0 ? "" : "2px solid #0558FF",
          background: currentBalance == 0 ? "#E6E6E6" : "#0558FF",
          height: "44px",
          width: "120px",
          fontSize: "16px",
          fontWeight: 600,
          whiteSpace: "nowrap",
        }}
      >
        Redeem Now
      </Button>
      {openW9ExistAlert && (
        <RedeemRewards
          oeid={oeid}
          portbu={portbu}
          accessToken={accessToken}
          close={handleClose}
          handleRedeemAmount={handleRedeemAmount}
        />
      )}
    </div>
  );
}
export default React.memo(RedeemButton)
