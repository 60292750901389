import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

const cookies = new Cookies(window.document.cookie)
export const GetBankerRewardsDetailsById = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken();
		if (accessToken) {
			const response = await axios({
                url: `${config.base_url}/api/getBankerRewardsDetailsById`,
				method: 'POST',
				data: JSON.stringify({
					"Pbu": cookies.get('portbu'),
					"Oeid": cookies.get('oeid')
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = response.data;
			if (!responseBody.error) {
				dispatch({
					type: 'FETCH_BANKERS_REWARDS_DETAILS',
					payload: responseBody
				})
				return true;
			}
			
		}
	} catch (error) {
		dispatch({
			type: 'FAILED_BANKERS_REWARDS_DETAILS',
			payload: error.message || "An error has occured."
		})
		return false
	}
}
export const GetPotentialProjectionDetails = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken();
		if (accessToken) {
			const response = await axios({
                url: `${config.base_url}/api/getPotentialProjectionDetails`,
				method: 'POST',
				data: JSON.stringify({
					"Pbu": cookies.get('portbu'),
					"Oeid": cookies.get('oeid')
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = response && response.data && Array.isArray(response.data["Data"]) ? response.data["Data"][0] : null;
			if (!responseBody.error) {
				dispatch({
					type: 'FETCH_BANKERS_POTENTIAL_REWARDS_PROJECTION_DETAILS',
					payload: responseBody
				})
				return true;
			}
			else if(responseBody.error){
				throw responseBody;
			}
			
		}
	} catch (error) {
		dispatch({
			type: 'FAILED_BANKERS_POTENTIAL_REWARDS_PROJECTION_DETAILS',
			payload: error.message || "An error has occured."
		})
		return false
	}
}