import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'
import moment from "moment";
import currency from 'currency.js';

export const fetchDownloadPromotionReport = async ({ PromotionId, PBU, OEID, IsDownload }) => {
    const accessToken = await getAccessToken();
 	try{
 		const response = await axios({
            url: `${config.base_url}/api/QaReports/GetQAReport`,
            //url: `https://o056mlitq8.execute-api.us-east-2.amazonaws.com/uat/GetQAReport`,
 			method: 'POST',
 			headers: {
 				'Content-Type': 'application/json',
 				'Authorization': `${accessToken}`,
 			},
            data: JSON.stringify({
                PromotionId: PromotionId,
                PBU: PBU,
                OEID: OEID,
                IsDownload: IsDownload,
            })
            })
         const responseBody = await response.data;
         return {
             error: null,
             data: responseBody.Data
         }
 	}catch(error){
 		return {
             error: (error.response && error.response.data.error) || 'Something went wrong!',
             data: []
 		}
 	}
}