import React, { Component, Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Grid, Paper, Link, Box, Typography } from "@material-ui/core";
import NavBar from "./NavBar";
import { logout } from "~/redux/actions/user";
import "./styles.scss";
import { AlertDialog } from "../Dialogs";
import { userInfo } from "~/redux/actions/user";
import SalesTip from "./SalesTip";
import { withRouter } from "react-router-dom";
import CustomDropdown from "../../../src/components/CustomDropdown";
import { selectHeaderItem } from "../../data/SelectItemList";
const ModalComponent = lazy(() =>
  import("../../components/Dashboard/VideoComponent/IframeComponent")
);
const GuidedTour = lazy(()=> import ("../GuidedTour"));

class Header extends Component {
  state = {
    dialogActive: false,
    title: "",
    message: "",
    salesTip: false,
    selectedOption: 0,
    featureWalkthrough: false,
    videoWalkthrough: false,
    featureStatus: false,
    salesTipOption: selectHeaderItem[2],
  };

  componentDidMount() {
    // to make salestip not appear evertime while in session
    if (sessionStorage.getItem("salesTip")) {
      this.setState({ salesTip: false });
      this.setState({ salesTipOption: selectHeaderItem[3] });
      this.onSelectChange(selectHeaderItem[3].value);
    }

    this.props.dispatch(userInfo()).then(() => {
      this.setState({ isLoading: false });
    });
    //toggling salestip overall in dashboard
    window.handleSalesTipGlobal = this.handleSalesTip;
    window.closeSalesTip = this.handleSalesTipClose;

    const userDetail = this.props.user.info.userInfo;
    if (userDetail) {
      if (userDetail.IsFeatureWalked && userDetail.IsVideoWalked) {
        if (sessionStorage.getItem("salesTip")) {
          this.setState({ salesTip: false });
          this.setState({ salesTipOption: selectHeaderItem[3] });
          this.onSelectChange(selectHeaderItem[3].value);
        } else {
          this.setState({ salesTip: true });
          sessionStorage.setItem("salesTip", true);
        }
      } else if (!userDetail.IsFeatureWalked && !userDetail.IsVideoWalked) {
        this.setState({ salesTipOption: selectHeaderItem[3] });
        this.onSelectChange(selectHeaderItem[3].value);
      } else if (userDetail.IsFeatureWalked && !userDetail.IsVideoWalked) {
        this.setState({ salesTipOption: selectHeaderItem[3] });
        this.onSelectChange(selectHeaderItem[3].value);
      }
    }
  }

  logout() {
    this.props.dispatch(logout());
    this.props.history.push("/logout");
    sessionStorage.clear();
  }
  getStatus = (val) => {
    this.setState({ featureStatus: val });
  };
  handleSalesTip = () => {
    this.setState({ salesTip: true });
    //this.setState({ salesTipOption: selectHeaderItem[2] });
  };

  handleFeatureWalkthrough = () => {
    this.setState({ featureWalkthrough: true });
  };
  closeFeatureWalkthrough = () => {
    this.setState({ featureWalkthrough: false, selectedOption: -1 });
  };

  handleVideo = () => {
    this.setState({ videoWalkthrough: true });
  };

  getCloseKey = () => {
    this.setState({ videoWalkthrough: false, selectedOption: -1 });
  };
  getPlayKey = () => {
    this.setState({ videoWalkthrough: true });
  };
  handleSalesTipClose = () => {
    this.setState({
      salesTip: false,
    });
    this.setState({ salesTipOption: selectHeaderItem[3] });
    this.onSelectChange(selectHeaderItem[3].value);
  };
  onSelectChange = (selectedOption) => {
    switch (selectedOption) {
      case 0:
        this.setState({ selectedOption: 0 });
        this.handleSalesTip();
        break;
      case 1:
        this.setState({ selectedOption: 1 });

        this.handleFeatureWalkthrough();
        break;
      case 2:
        this.setState({ selectedOption: 2 });
        this.handleVideo();
        break;
      case 3:
        this.setState({ selectedOption: 3 });
        break;
      default:
        console.error("error");
    }
  };

  handleSalesTipChange = (value) => {
    const selectedOption = selectHeaderItem.find(
      (item) => item.value === value
    );
    if (value === 1) {
      this.setState({ selectedOption: 3 });
      this.handleFeatureWalkthrough();
    } else {
      this.setState({ salesTipOption: selectedOption });
      this.onSelectChange(selectedOption.value);
    }
  };

  render() {
    const {
      title,
      message,
      dialogActive,
      salesTip,
      featureWalkthrough,
      videoWalkthrough,
      salesTipOption,
    } = this.state;
    const userInfo = this.props.info;
    const { isLoggedIn, location } = this.props;
    const isDashboard = location.pathname.split("/")[1] === "dashboard";

    return (
      <Fragment>
        <Suspense fallback={<div>...Loading</div>}>
          {videoWalkthrough && (
            <ModalComponent
              clicked={videoWalkthrough}
              toggleClose={this.getCloseKey}
              closeTooltip={this.handleSalesTipClose}
              handleSalesTip={this.handleSalesTip}
            />
          )}
        </Suspense>

        {featureWalkthrough && (
          <Suspense fallback={<div>Loading...</div>}>
          <GuidedTour
            tourRun={featureWalkthrough}
            closeTourRun={this.closeFeatureWalkthrough}
            openSalestip={this.onSelectChange}
            closeSalesTip={this.handleSalesTipClose}
          />
          </Suspense>
        )}
        <Paper square id="header">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="header-top"
          >
            <Grid item md={4}>
              <div className="logo-wrap">
                <img
                  alt="elanLogo"
                  src={require("~/assets/images/cashin-logo.svg")}
                  style={{height:"67.58px", width:"240px"}}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="walkthrough-header">
                {isDashboard && (
                  <div style={{ marginRight: "20px" }} className="help_center">
                    <CustomDropdown
                      selectedOption={salesTipOption}
                      id="help_center"
                      menuWidth="225px"
                      menuHeight="auto"
                      align="bottom"
                      menuPosition={{ right: "0", top: "110%" }}
                      buttonStyle={{
                        backgroundColor:
                          salesTipOption.value == 3 ? "#E6E6E6" : "#0558FF",
                        color: salesTipOption.value == 3 ? "#000" : "#fff",
                        padding: "8px 25px 8px 16px",
                        fontSize: "16px",
                        height: "44px",
                        width: "150px",
                        fontWeight: 600,
                        zIndex: 10,
                      }}
                      iconStyle={{
                        right: "10px",
                        top: "55%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {selectHeaderItem
                        .filter((option) => option.value !== 3)
                        .map((option) => (
                          <li
                            key={option.value}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "10px",
                              cursor: "pointer",
                              color: "#084BB7",
                              height:"20px",
                              backgroundColor:
                                option.value === selectHeaderItem.value
                                  ? "null"
                                  : "transparent",
                              textDecoration:
                                option.value === selectHeaderItem.value
                                  ? "underline"
                                  : "none",
                            }}
                            onMouseEnter={(e) => {
                              if (option.value !== selectHeaderItem.value) {
                                e.currentTarget.style.backgroundColor =
                                  "transparent";
                                e.currentTarget.style.textDecoration =
                                  "underline";
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (option.value !== selectHeaderItem.value) {
                                e.currentTarget.style.backgroundColor =
                                  "transparent";
                                e.currentTarget.style.textDecoration = "none";
                              }
                            }}
                            onClick={() =>
                              this.handleSalesTipChange(option.value)
                            }
                          >
                            {option.icon && (
                              <img
                                alt="icon"
                                src={option.icon}
                                style={{ padding: "5px" }}
                              />
                            )}
                            {option.item}
                          </li>
                        ))}
                    </CustomDropdown>
                  </div>
                )}
                <Box
                  sx={{
                    position: "fixed", // Use absolute positionin              // Aligns with the top of the container (or you can adjust)
                    display: "flex",
                    flexDirection: "column", // Stack items vertically
                    alignItems: "flex-end", // Align items to the end (right side)
                    paddingY: 1, // Optional vertical padding for better alignment
                    zIndex: 1000, // Ensure it stays on top of other content
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    {isDashboard && salesTip && (
                      // <Grid item>
                        <SalesTip
                          closeTooltip={this.handleSalesTipClose}
                          openTooltip={this.handleSalesTip}
                        />
                      // </Grid>
                    )}

                    {isLoggedIn && (
                      <>
                        {userInfo && (
                          <Grid item>
                            <Typography
                              variant="body1"
                              style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              }}
                            >
                              {`Welcome, ${userInfo.FirstName}`}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={this.logout.bind(this)}
                            style={{
                              marginRight: "20px",
                              fontSize: "14px",
                              fontWeight: 600,
                              color: "#000",
                            }}
                          >
                            Logout
                          </Link>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Box>
          <Box className="header-bottom">
            <NavBar {...this.props} />
          </Box>
        </Paper>
        {dialogActive && (
          <AlertDialog
            success={true}
            title={title}
            message={message}
            onConfirm={() => {
              this.setState({ dialogActive: false, title: "", message: "" });
              this.props.dispatch(logout());
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default connect((state) => ({ ...state.user, ...state.Video_Reducer }))(
  withRouter(Header)
);
