import React, { useMemo } from "react";
import LeaderboardRankingCard from "./LeaderboardRankingCard";
import LeaderboardTable from "./LeaderboardTable";
import {
  CardContent,
  Typography,
  Box,
  Card,
  Tooltip,
  Tabs,
  Tab,
} from "@material-ui/core";
import SelectDropdown from "../../SelectDropdown";
import { InfoOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import CustomDropdown from "../../../components/CustomDropdown";
const Leaderboard = ({
  selectedTab,
  promoFilterOption,
  leaderboardSelectList,
  selectedOption,
  handleLeaderboardFilter,
  handleLeaderBoardTabs,
}) => {
  const LeaderboardTableData = useSelector(
    (state) => state.leaderboard.leaderboard.leaderboardTableData
  );

  const [allPromOptn, setAllPromOptn] = React.useState(
    leaderboardSelectList[0]
  );
  const ShowMaskInfo = useMemo(() => {
    if (selectedTab === 1) {
      return false;
    } else
      return (
        LeaderboardTableData && LeaderboardTableData.bankersNameMasked == 1
      );
  }, [selectedTab, LeaderboardTableData]);
  const handleAllPromotionChange = (value) => {
    const selectedOptions = leaderboardSelectList.find(
      (item) => item.value === value
    );
    setAllPromOptn(selectedOptions);
    handleLeaderboardFilter(selectedOptions.value);
  };
  return (
    <>
      <Card
        style={{
          background: "#FFFFFF",
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <CardContent style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flexGrow: 1 }}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "24px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Leaderboard
              <Tooltip
                title={
                  <div className="textTooltip">
                    The earnings displayed are the amounts earned in the current
                    year (beginning Jan 1st)
                  </div>
                }
                arrow={true}
                placement="bottom"
                classes={{
                  tooltip: "iconTooltip",
                  arrow: "iconArrow",
                }}
              >
                <InfoOutlined fontSize="small" style={{ marginLeft: 4 }} />
              </Tooltip>
            </Typography>
            <Typography
              style={{
                marginRight: "5px",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              See how you match up against your peers based on rewards earned.
            </Typography>
          </div>
          {/* <SelectDropdown
            selectList={leaderboardSelectList}
            selectedValue={selectedOption}
            onSelectChange={handleLeaderboardFilter}
          /> */}
         
          <CustomDropdown
            selectedOption={allPromOptn}
            id="all_promotion"
            menuWidth="225px"
            menuHeight="auto"
            align="bottom"
            menuPosition={{
              right: "0",
              top: "110%",
              fontSize: "14px",
              fontWeight: 500,
              color: "#084BB7",
              height: leaderboardSelectList.length > 5 ? "185px" : "auto",
              overflowY: leaderboardSelectList.length > 5 ? "scroll" : "none",
              overflowX: "hidden",
            }}
            buttonStyle={{
              backgroundColor: allPromOptn.item==="All Promotion"?"#E6E6E6": "#0558FF" ,
              color: allPromOptn.item==="All Promotion"? "#000":"#FFF",
              padding: "8px 25px 8px 16px",
              fontSize: "16px",
              height: "44px",
              width: "150px",
              fontWeight: 600,
              zIndex: 10,
            }}
            iconStyle={{
              right: "4px",
              top: "55%",
              transform: "translateY(-50%)",
            }}
          >
            {leaderboardSelectList.map((option) => (
              <li
                key={option.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  color: "#084BB7",
                  backgroundColor:
                            option.value ===allPromOptn.value
                              ? "null"
                              : "transparent",
                              textDecoration:
                            option.value ===allPromOptn.value
                              ? "underline"
                              : "none",
                }}
                onMouseEnter={(e) => {
                  if (option.value !== allPromOptn.value) {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.textDecoration = "underline";
                  }
                }}
                onMouseLeave={(e) => {
                  if (option.value !== allPromOptn.value) {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.textDecoration = "none";
                  }
                }}
                onClick={() => handleAllPromotionChange(option.value)}
              >
                {option.item}
              </li>
            ))}
          </CustomDropdown>
        </CardContent>
        <Tabs
          value={selectedTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleLeaderBoardTabs}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            marginLeft: "13px",
            marginRight: "15px",
            // boxSizing:"border-box"
          }}
        >
          <Tab
            label={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  fontSize: "14.5px",
                  fontWeight: selectedTab !== 1 ? 600 : 400,
                  color: selectedTab !== 1 ? "#0558FF" : "#000",
                }}
              >
                My FI Ranking
                <Tooltip
                  title={
                    <div className="textTooltip">
                      See your position against your peers in the same financial
                      institution
                    </div>
                  }
                  arrow={true}
                  placement="bottom"
                  classes={{
                    tooltip: "iconTooltip",
                    arrow: "iconArrow",
                  }}
                >
                  <InfoOutlined
                    fontSize="small"
                    style={{ marginLeft: 4, color: "#000" }}
                  />
                </Tooltip>
              </div>
            }
            style={{
              textTransform: "none",
              color: "black",
              fontWeight: "bold",
            }}
          />
          <Tab
            label={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  fontSize: "14.5px",
                  fontWeight: selectedTab === 1 ? 600 : 400,
                  color: selectedTab === 1 ? "#0558FF" : "#000",
                }}
              >
                My Earnings Tier
                <Tooltip
                  title={
                    <div className="textTooltip">
                      View your tier placement based on total rewards earned and view your ranking among employees from all Elan Financial Institutions.
                    </div>
                  }
                  arrow={true}
                  placement="bottom"
                  classes={{
                    tooltip: "iconTooltip hgt90px",
                    arrow: "iconArrow",
                  }}
                >
                  <InfoOutlined
                    fontSize="small"
                    style={{ marginLeft: 4, color: "#000" }}
                  />
                </Tooltip>
              </div>
            }
            style={{
              textTransform: "none",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </Tabs>
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            background: "#ffffff",
          }}
        >
          <LeaderboardRankingCard selectedTab={selectedTab} />
        </CardContent>
        <CardContent
          style={{
            display: "flex",
            alignItems: "center",
            background: "#ffffff",
            height: "260px",
            fontSize: "14px",
            fontWeight: 600,
            marginBottom: "5px",
          }}
        >
          <LeaderboardTable
            selectedTab={selectedTab}
            filterOption={promoFilterOption}
          />
        </CardContent>
        {ShowMaskInfo && (
          <CardContent>
            <Box
              style={{
                background: "#E6E6E6",
                display: "flex",
                alignItems: "center",
                padding: "2px",
                gap: "8px",
                height: "35px",
              }}
            >
              <img alt={`Icon Exclamation`} src={require("~/assets/images/dashboard/footnote-behind.svg")} style={{ marginLeft: "10px" }}/>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "21px",
                }}
              >
                Employee names are masked for privacy.
              </Typography>
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default Leaderboard;
