import React from 'react';
import { Button, Grid, Box, DialogTitle, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './styles.scss'
import { CircularProgress } from '@material-ui/core';


export function ConfirmDialog(props) {
    const { title, message, onConfirm, onCancel } = props
    return (
        <div id="mainDialogs">
            <Dialog
                open={true}
                onClose={onCancel}
                // aria-labelledby="alert-dialog-title" 
                // aria-describedby="alert-dialog-description" 
            >
                
                <Box py={6} px={6}>
                    <DialogTitle className="dialogTitle">{title}</DialogTitle>
                    <DialogContent>
                        <div className="dialogContent">{message}</div>
                    </DialogContent>
                </Box>
                <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
                    <Button variant="contained" className="noBtn" fullWidth={true} onClick={onCancel} color="primary">
                        No
                    </Button>
                    <Button variant="contained" className="yesBtn" fullWidth={true} onClick={onConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}


export function AlertOTPDialog(props) {
    const { dialogClassName="", title,title1,title2, message, confirmText, onConfirm, px, py, clickOutsideToClose,validateOTP, show = true, success = false, showCancelBtn = false, onClose, btnDisabled = btnDisabled ? btnDisabled : false, outsideClick,
    showContinueBtn=false, onContinue, continueText, redeemDisabled,downloadProgress,attempts } = props;
    // const dialogStyles = {
    //     maxWidth: '3000px',
    //     maxHeight: '500px',
    //   };
    return (
        <Dialog
            open={true}
            onClose={() => !outsideClick && (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={dialogClassName || ""}
            disableEscapeKeyDown BackdropProps={{}}
            // style={dialogStyles}
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
            <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
            <CloseIcon />
            </IconButton>
        ) : null}
                {success ?<Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <img alt="success" alignItems="center" src={require('~/assets/images/success-alert.png')} width="48" height="48" />
                </Box>: null}
                {confirmText && confirmText== 'DISMISS' ?<h1 className="error-header">Oops!</h1>:null}
                {title && <DialogTitle className="alert-dialog-title">{title}</DialogTitle>}
                {title1 && <DialogTitle className="alert-dialog-title">{title1}</DialogTitle>}
                    {title2 && <DialogTitle className="alert-dialog-title">{title2}</DialogTitle>}
                {(message || props.children) && (
                    <DialogContent className="alert-dialog-message">
                        {message && <Box color="primary.main" mb={2}>{message}</Box>}
                        {props.children && props.children}
                    </DialogContent>
                )}
               {show ? <DialogActions className="alert-dialog-action">
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                            {showContinueBtn ? !downloadProgress?<Button style={attempts?{ color: "#b4a5a5", backgroundColor: "#e0e0e0" }:{ color: 'white', backgroundColor: "blue" }}  variant="contained" disabled={attempts} onClick={validateOTP}>
                                Continue
                    </Button>:
                    <CircularProgress color="primary" /> : null
                    
                    }
                        </Box>
                    </DialogActions> : null}
            </Box>
        </Dialog>
    );
}
export function AlertDialog(props) {
    const { dialogClassName="", title, message, confirmText, onConfirm, px, py, clickOutsideToClose, show = true, success = false, showCancelBtn = false, onClose, btnDisabled = btnDisabled ? btnDisabled : false, outsideClick,
    showContinueBtn=false, onContinue, continueText, redeemDisabled } = props
    return (
        <Dialog
            open={true}
            onClose={() => !outsideClick && (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            // aria-labelledby="alert-dialog-title"
            // aria-describedby="alert-dialog-description"
            className={dialogClassName || ""}
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
            <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
            <CloseIcon />
            </IconButton>
        ) : null}
                {success ?<Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <img alt="success" alignItems="center" src={require('~/assets/images/success-alert.png')} width="48" height="48" />
                </Box>: null}
                {confirmText && confirmText== 'DISMISS' ?<h1 className="error-header">Oops!</h1>:null}
                {title && <DialogTitle className="alert-dialog-title">{title}</DialogTitle>}
                {(message || props.children) && (
                    <DialogContent className="alert-dialog-message">
                        {message && <Box color="primary.main" mb={2}>{message}</Box>}
                        {props.children && props.children}
                    </DialogContent>
                )}
                {show ? <DialogActions className="alert-dialog-action">
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    {showCancelBtn?<Button variant="contained" className="redeem-btn" onClick={clickOutsideToClose} >
                                Cancel
                    </Button>: null }
                        {showContinueBtn?<Button variant="contained" className="redeem-btn" onClick={onContinue} >
                             {continueText ? continueText : 'Continue'}
                        </Button>: null }
                        <Button disabled={btnDisabled === true || redeemDisabled === true ? true : false} style={btnDisabled === true || redeemDisabled === true ? { opacity: 0.5 } : undefined} variant="contained" onClick={onConfirm} color="primary" autoFocus>
                                {confirmText ? confirmText : 'OK'}
                        </Button>
                    </Box>
                </DialogActions> :null}
            </Box>
        </Dialog>
    );
    }
