import React, { Component, Suspense, lazy } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import "./styles.scss";
import QuestionComponent from "../../components/Dashboard/Surveys";
import { GetBankerRewardsDetailsById } from "../../redux/actions/dashboard";
import { PersonalizeCalculator } from "../../components/Dashboard/PersonalizeCalculator";
import { GetAllPromotions } from "../../redux/actions/leaderboard";
import VideoModal from "../../components/Dashboard/VideoComponent";
import GuidedTour from "../../components/GuidedTour";
import { storeWalkthroughRespone } from "../../redux/actions/video";
import IframeComponent from "../../components/Dashboard/VideoComponent/IframeComponent";
import RedeemButton from "../Redeem/RedeemButton/RedeemButton";
// import RewardsProjection from "../../components/Dashboard/RewardsProjection";
import Leaderboard from "../../components/Dashboard/Leaderboard";
import { helpers } from "chart.js";

const RewardsProjection = lazy(() =>
  import("../../components/Dashboard/RewardsProjection")
);

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedTab: 0,
    earnedYTD: "",
    currentBalance: "",
    openCalculator: false,
    openPopup: false,
    copyCodeClicked: false,
    leaderboardSelectList: [
      {
        value: 0,
        item: "All Promotion",
      },
    ],
    selectedOption: 0,
    isFeatureWalked: "",
    isVideoWalked: "",
    featureWalkthrough: true,
    videoWalkthrough: true,
    promoFilterOption: 0,
  };

  componentDidMount() {
    this.props.dispatch(GetBankerRewardsDetailsById()).then((response) => {
      if (response) {
        this.setState({
          currentBalance: this.props.dashboard.currentBalance,
          earnedYTD: this.props.dashboard.earnedYTD,
        });
      }
    });

    this.props.dispatch(GetAllPromotions()).then((response) => {
      if (response) {
        const getAllPromotons = this.props.leaderboard.allPromotions.map(
          (promotions) => ({
            value: promotions.PromotionID,
            item: promotions.PromotionName,
          })
        );
        this.setState((prevState) => ({
          leaderboardSelectList: [
            ...prevState.leaderboardSelectList,
            ...getAllPromotons,
          ],
        }));
      }
    });
    this.setState({
      isFeatureWalked: this.props.user.info.userInfo.IsFeatureWalked,
      isVideoWalked: this.props.user.info.userInfo.IsVideoWalked,
    });

    document.body.classList.add("fixedWidthWithScroll");
  }
  handleRedeemAmount = (redeemVal) => {
    if (redeemVal === true) {
      this.props.dispatch(GetBankerRewardsDetailsById()).then((response) => {
        if (response) {
          this.setState({
            currentBalance: this.props.dashboard.currentBalance,
          });
        }
      });
    }
  };
  handleRedeem = () => {
    this.props.clickEvent();
  };
  handleLeaderBoardTabs = (event, selectedTab) => {
    this.setState({ selectedTab: selectedTab });
  };

  openPersonalizeCalculator = () => {
    this.setState({ openCalculator: true });
  };

  closePersonalizeCalculator = () => {
    this.setState({ openCalculator: false });
  };
  handleBankerCopyCode = () => {
    this.setState({ openPopup: true, copyCodeClicked: true });
    navigator.clipboard.writeText(this.props.user.info.userInfo.BankerCode);
    setTimeout(
      () => this.setState({ openPopup: false, copyCodeClicked: false }),
      2000
    );
  };

  handleLeaderboardFilter = (selectedOption) => {
    this.setState({ promoFilterOption: selectedOption });
  };

  handleCloseWalkThrough = () => {
    this.setState({ featureWalkthrough: false });
  };
  getCloseKey = () => {
    this.setState({ videoWalkthrough: false });
  };

  componentWillUnmount() {
    document.body.classList.remove("fixedWidthWithScroll");
  }

  render() {
    const {
      selectedTab,
      currentBalance,
      earnedYTD,
      openCalculator,
      openPopup,
      copyCodeClicked,
      leaderboardSelectList,
      selectedOption,
      isVideoWalked,
      isFeatureWalked,
      featureWalkthrough,
      videoWalkthrough,
      promoFilterOption,
    } = this.state;
    return (
      <div className="dashboard">
        {!isVideoWalked && !isFeatureWalked && (
          <VideoModal
            isVideoWalked={isVideoWalked}
            isFeatureWalked={isFeatureWalked}
          />
        )}
        {!isVideoWalked && isFeatureWalked && videoWalkthrough && (
          <IframeComponent
            clicked={videoWalkthrough}
            toggleClose={this.getCloseKey}
            isFeatureWalked={this.props.user.info.userInfo.IsFeatureWalked}
            isVideoWalked={this.props.user.info.userInfo.IsVideoWalked}
          />
        )}
        {isVideoWalked && !isFeatureWalked && (
          <GuidedTour
            tourRun={featureWalkthrough}
            closeTourRun={this.handleCloseWalkThrough}
            IsFeatureWalked={this.props.user.info.userInfo.IsFeatureWalked}
            isFeatureWalked={this.props.user.info.userInfo.IsFeatureWalked}
          />
        )}
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={8}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Card
                  className="current_balance"
                  style={{
                    background: "#FFFFFF",
                    borderRadius: 0,
                    boxShadow: "none",
                    
                  }}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "12px",
                      marginLeft: "8px",
                      marginRight: "12px",
                      paddingBottom: "12px",
                      boxSizing: "border-box ",
                      height: "110px",
                      
                    }}
                  >
                    <img
                      src={require("~/assets/images/dashboard/Current-Balance.svg")}
                      alt=""
                      style={{ marginRight: "16px", borderTopRightRadius:"13px" }}
                    />
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, fontSize: "16px" }}
                      >
                        Current Balance
                      </Typography>
                      <Typography
                        component="h1"
                        style={{ fontWeight: 700, fontSize: "34px" }}
                      >
                        ${currentBalance}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <RedeemButton
                        currentBalance={currentBalance}
                        handleRedeemAmount={this.handleRedeemAmount}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                className="earned_ytd"
                  style={{
                    background: "#FFFFFF",
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "12px",
                      marginLeft: "8px",
                      marginRight: "12px",
                      paddingBottom: "12px",
                      boxSizing: "border-box ",
                      height: "110px",
                    }}
                  >
                    <img
                      src={require("~/assets/images/dashboard/currency-ytd.svg")}
                      alt=""
                      style={{ marginRight: "16px",borderTopRightRadius:"13px" }}
                    />
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 400, fontSize: "16px" }}
                      >
                        Current YTD Earnings
                      </Typography>
                      <Typography
                        component="h1"
                        style={{ fontWeight: 700, fontSize: "34px" }}
                      >
                        ${earnedYTD}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12} className="rewards_projection">
                <Card
                  style={{
                    background: "#FFFFFF",
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      {/* <Grid item xs={12}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Grid item xs={6}>
                            <Typography
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              Reward Projection
                              <InfoOutlined />
                            </Typography>
                            <Typography>
                              Current your earnings track last year
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              className="calculate_earning"
                              variant="contained"
                              style={{
                                background: "#E6E6E6",
                                fontWeight: "bold",
                              }}
                              onClick={this.openPersonalizeCalculator}
                            >
                              Calculate Earnings
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* <Grid item xs={4}>
                            <Typography
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              $640{" "}
                            </Typography>
                            <Typography>Potential Rewards</Typography>
                            <Typography
                              style={{
                                color: "#33C3A4",
                                fontWeight: "bold",
                                padding: "15px 15px 15px 2px",
                              }}
                            >
                              <img
                                src={require("~/assets/images/dashboard/arrow_drop_up.png")}
                                style={{
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                                alt=""
                              />
                              +2.45%
                            </Typography>
                            <Typography
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              $320{" "}
                            </Typography>
                            <Typography>Rewards earned</Typography>
                          </Grid> */}
                          <Grid item xs={12} style={{ paddingBottom: 0 }}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <RewardsProjection
                                openPersonalizeCalculator={
                                  this.openPersonalizeCalculator
                                }
                              />
                            </Suspense>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Box
                          style={{
                            background: "#E6E6E6",
                            visibility: "20%",
                            padding: "10px",
                          }}
                        >
                          <Typography>
                            Good Job! Per your current earnings you are on track
                            to earn 1.5x rewards from last year
                          </Typography>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <div className="survey_tile">
                  <Card
                    style={{
                      background: "#FFFFFF",
                      marginTop: "20px",
                      borderRadius: 0,
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <QuestionComponent />
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} className="leader_board" id="leader_board_grid">
                <Leaderboard
                  selectedTab={selectedTab}
                  promoFilterOption={promoFilterOption}
                  leaderboardSelectList={leaderboardSelectList}
                  selectedOption={selectedOption}
                  handleLeaderboardFilter={this.handleLeaderboardFilter}
                  handleLeaderBoardTabs={this.handleLeaderBoardTabs}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  style={{
                    background: "#FFFFFF",
                    marginTop: "20px",
                    borderRadius: 0,
                    boxShadow: "none",
                    minHeight: "163px",
                  }}
                  className="banker_code"
                >
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "15px",
                    }}
                  >
                    <img
                      src={require("~/assets/images/dashboard/selfApplyCode.svg")}
                      alt=""
                      style={{ marginRight: "16px", marginBottom: "50px",borderTopRightRadius:"13px" }}
                      width="56px"
                      height="56px"
                    />
                    <div>
                      <Typography
                        style={{ fontWeight: 600, fontSize: "20px" }}
                      >
                        My Self-Apply Code
                      </Typography>
                      <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                        Don't forget to share your Self-Apply code and earn your
                        much deserved rewards!
                      </Typography>
                      <Tooltip
                        open={openPopup}
                        title={
                          <div className="textBankerTooltip">
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                whiteSpace: "nowrap",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "centre",
                                paddingTop: "12px, 16px, 12px, 16px",
                                marginTop: "8px",
                              }}
                            >
                              Code Copied
                            </Typography>
                          </div>
                        }
                        arrow={true}
                        placement="right"
                        classes={{
                          tooltip: "bankerTooltip",
                          arrow: "bankerArrow",
                        }}
                      >
                        <Button
                          className="CopyCode_Button"
                          onClick={this.handleBankerCopyCode}
                          style={{
                            width: "150px",
                            height: "44px",
                            fontWeight: 600,
                            lineHeight: "21px",
                            marginTop: "10px",
                            position: "relative",
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                            border:
                              copyCodeClicked !== true
                                ? "2px solid #E6E6E6"
                                : "2px solid #000",
                            background:
                              copyCodeClicked !== true ? "#E6E6E6" : "#fff",
                          }}
                        >
                          Copy Code
                        </Button>
                      </Tooltip>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PersonalizeCalculator
              openCalculator={openCalculator}
              closeCalculator={this.closePersonalizeCalculator}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect((state) => ({
  ...state.user,
  ...state.redeem,
  ...state.dashboard,
  ...state.leaderboard,
}))(Dashboard);
