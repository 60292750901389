var initialState = {
    reports: {
        list: [],
        fetchListError: null,
        reportsTotalCount:0,
        totalCount:0,
        error:null,
    },
}

export default function reports(state = initialState, action = {}) {
    switch (action.type) {
        case 'REPORTS_LIST_FETCH_SUCCESS':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    list: action.payload,
                    reportsTotalCount:action.totalCount,
                    fetchListError: null,
                }
            }
        case 'REPORTS_LIST_FETCH_FAILED':
            return {
                ...state,
                reports: {
                    ...state.reports,
                    fetchListError: action.payload.error
                }
            }
        default:
            return {
                ...state
            }
    }
}