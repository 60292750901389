export const activationRates = [
    {
        value: 80,
        item: '80%'
    },
    {
        value: 85,
        item: '85%'
    },
    {
        value: 90,
        item: '90%'
    },
    {
        value: 95,
        item: '95%'
    },
    {
        value: 100,
        item: '100%'
    }
];

export const calculationParameters = ["Consumer Accounts Booked", "Business Accounts Booked", "Activation Rate", "Balance Transfers"];
export const calculationParameterNames = {
    CONSUMER_CARD_BOOKED: "Consumer Accounts Booked",
    BUSINESS_CARD_BOOKED: "Business Accounts Booked",
    ACTIVATION_RATE: "Activation Rate",
    FUNDED_BALANCE_TRANFERS: "Balance Transfers"
};