import React, { useEffect, useState, createRef } from "react";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { storeWalkthroughRespone } from "../../../redux/actions/video";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iframe: {
    // borderRadius: "13.31px",
    position: "relative",
  },
  overlayButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    cursor: "pointer",
    transition: "filter 0.3s ease",
    "&:hover": {
      filter: "brightness(0.7)",
    },
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    columnGap: 1,
  },
  button: {
    backgroundColor: "#1565c0",
    color: "#fff",
    // borderRadius: "4px",
    fontFamily: "USBankCircular",
  },
  skipButton: {
    color: "#1565c0",
    fontFamily: "USBankCircular",
    marginTop: "5px",
    // borderRadius: "4px",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    backgroundColor: "#E6E6E6",
  },
}));

const IframeComponent = (props) => {
  const {
    IframeModal,
    handleCloseIframe,
    playVideo,
    clicked,
    toggleClose,
    closeClick,
    closeTooltip,
    IsVideoWalked,
  } = props;
  const videoRef = createRef();
  const dispatch = useDispatch();
  const userLoginData = useSelector((state) => state.user.user.info.userInfo);

  const classes = useStyles();
  const [status, setStatus] = React.useState();
  const [showPlayButton, setShowPlayButton] = useState(false);

  useEffect(() => {
    if (clicked) {
      setStatus(true);
    }
  }, [clicked]);

  const handleVideo = () => {
    if (!IsVideoWalked) {
      dispatch(storeWalkthroughRespone(true, true));
    }
    videoRef.current.play();

    if (clicked === true) {
      setShowPlayButton(true);
    }
  };
  const closeIframe = () => {
    if (typeof toggleClose === "function") {
      toggleClose();
    }
    if (typeof closeClick === "function") {
      closeClick();
    }
    if (typeof closeTooltip === "function") {
      closeTooltip();
    }
    if (userLoginData.IsFeatureWalked && !userLoginData.IsVideoWalked) {
      window.handleSalesTipGlobal();
    }
    setShowPlayButton(true); // Show play button when the modal is reopened
  };

  React.useEffect(() => {
    if (IframeModal || clicked) {
      setStatus(true);
    }
  }, [IframeModal, clicked]);

  return (
    <Modal
      open={status}
      onClose={handleCloseIframe}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
    >
      <Card
        className={classes.card}
        style={{
          height: "472px",
          width: "682.14px",
          borderRadius: "0",
          outline: "none",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            padding: "16px", // Optional: Add padding for better spacing
            boxSizing: "border-box", // Ensure padding doesn't affect the overall dimensions
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row", // Row direction for the header section
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%", // Ensure it takes full width
              marginBottom: "16px", // Space below the header section
              marginTop: "-12px",
            }}
          >
            <div>
              <h2 className={classes.videoText}>
                Introducing the all new CA$HIN!
              </h2>
            </div>
            <div className={classes.closeIcon} onClick={closeIframe}>
              <Close />
            </div>
          </div>

          <div
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "648.71px",
              marginTop: "-12px",
            }}
          >
            <video
              poster={require("~/assets/images/dashboard/walkthrough_video_thumbnail.png")}
              ref={videoRef}
              width="100%" // Make video responsive
              height="386.14px" // Fixed height
              autoPlay={playVideo}
              controls
              className={classes.iframe}
              src="https://elan-uat-images.payeecashin.com/ELAN-Banker-Portal-Video_V1.mp4"
              title="Introduction Video"
              style={{
                objectFit: "cover", // Ensures the video covers the given dimensions
                // borderRadius: "8px", // Optional: Add border radius for styling
              }}
              onPlay={()=>{
                setShowPlayButton(true);
              }}
              onPause={()=>{
                setShowPlayButton(false);
              }}
            ></video>
            {!playVideo && !showPlayButton && (
              <img
                className={classes.overlayButton}
                onClick={handleVideo}
                src={require("~/assets/images/dashboard/play.png")}
                alt="Play Video"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default IframeComponent;
