import { combineReducers } from 'redux'
import user from './user'
import payments from './payment'
import redeem from './redeem'
import manageAccount from './manage-account';
import support from './support';
import bankers from './bankers';
import taxations from './taxation';
import rewardType from './rewardType';
import reports from './reports';
import dashboard from './dashboard';
import Survey_Reducer from './survey_reducer';
import Video_Reducer from './video'
import leaderboard from './leaderboard';

const reducer = combineReducers({
	user,
	payments,
	redeem,
	manageAccount,
	support,
	bankers,
	taxations,
	rewardType,
	reports,
	dashboard,
	Survey_Reducer,
	Video_Reducer,
	leaderboard
})

export default reducer;