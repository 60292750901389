import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './styles.scss';
import { GetLeaderboardData } from '../../../../redux/actions/leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const FIRankingHeader = ['Ranking', 'Banker Name', 'Rewards Earned'];
const PromotionRankingHeader = ['Tier', 'Rewards Range', 'Employee Distribution'];

function LeaderboardTable({ selectedTab, filterOption }) {
  const dispatch = useDispatch();
  const LeaderboardTableData = useSelector((state) => state.leaderboard.leaderboard.leaderboardTableData);

  const [leaderboardData, setLeaderboardData] = useState(null);

  useEffect(() => {
    dispatch(GetLeaderboardData({ promoId: filterOption }));
  }, [filterOption]);

  useEffect(() => {
    if (LeaderboardTableData) {
      if (selectedTab == 0) {
        setLeaderboardData(LeaderboardTableData.FIRankingsJSON);
      }
      else {
        setLeaderboardData(LeaderboardTableData.PIRankingsJSON);
      }
    }
  }, [LeaderboardTableData, selectedTab]);

  return (
    <Paper className='leaderboard-table'>
      <Table>
        <TableHead>
          <TableRow>
            {selectedTab === 0 ? FIRankingHeader.map(header => (
              <TableCell className='table-cell-head'>{header}</TableCell>
            )) : PromotionRankingHeader.map(header => (
              <TableCell className='table-cell-head'>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody >
          {leaderboardData ? (() => {
            const rows = [];
            for (let i = 0; i < 5; i++) {
              const row = leaderboardData[i];
              rows.push(
                <TableRow key={i} >
                  <TableCell id="tableCell"className='table-cell-body' component="th" scope="row">
                    {selectedTab == 0 ? row ? row.Ranking : '' : row ? row.Tier : ''}
                  </TableCell>
                  <TableCell  id="tableCell" className='table-cell-body'style={{color:row && row.BankerName==='********'? "#969798": "#000",}}>{selectedTab == 0 ? row ? row.BankerName : '' : row ? row.RewardsRange : ''}</TableCell>
                  <TableCell id="tableCell" className='table-cell-body'>{selectedTab == 0 ? row ? row.RewardEarned : '' : row ? row.Percentage : ''}</TableCell>
                </TableRow>
              )
            }
            return rows;
          })() : null}
        </TableBody>
      </Table>
    </Paper>
  );
}

LeaderboardTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LeaderboardTable;
