import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, Link, Box } from '@material-ui/core';
import NavBar from './NavBar';
import { logout } from '~/redux/actions/user'
import './styles.scss';
import { AlertDialog } from '../Dialogs';
import { userInfo } from '~/redux/actions/user';

class MFAHeader extends Component {

    state = {
        dialogActive: false,
        title: "",
        message: ""
    }

    componentDidMount() {
        this.props.dispatch(userInfo()).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    logout() {
        this.props.dispatch(logout());
        this.props.history.push("/logout");
        //this.setState({ title: "Thank you!", message: "You have successfully logged out.", dialogActive: true });
    }

    render() {
        const userInfo = this.props.info;
        const { title, message, dialogActive } = this.state;
        const { isLoggedIn } = this.props;
        return (
            <Fragment>
                <Paper square id="header">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="header-top">
                        <Grid item md={4}>
                            <div className="logo-wrap"><img alt="elan" src={require('~/assets/images/elan-header-logo.png')} /></div>
                        </Grid>
                        
                    </Box>
                  
                </Paper>
               
            </Fragment>
        )
    }
}

export default connect(state => (
    { ...state.user }
))(MFAHeader)