import { Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import './styles.scss';
import { useSelector } from "react-redux";

const LeaderboardRankingCard = ({ selectedTab }) => {
    const LeaderboardTableData = useSelector((state) => state.leaderboard.leaderboard.leaderboardTableData);

    const [leaderboardPlacement, setLeaderboardPlacement] = useState(null);

    useEffect(() => {
        if (LeaderboardTableData) {
            if (selectedTab == 0) {
                setLeaderboardPlacement(LeaderboardTableData.MyRanking);
            }
            else {
                setLeaderboardPlacement(LeaderboardTableData.MyTier);
            }
        }
    }, [LeaderboardTableData, selectedTab]);

    return (
        <>
            <Card className="leaderboard-ranking-card" >
                <CardContent className="leaderboard-ranking-card-content">
                    <img src={require('~/assets/images/dashboard/rank.svg')} alt="leaderboard-card" className="card-content-img" />
                    <div className="ranking">
                        <Typography className="placement-text" >My Ranking</Typography>
                        <Typography className="ranking-text" component="h2" >{leaderboardPlacement ? leaderboardPlacement : <CircularProgress color="primary" />}</Typography>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default LeaderboardRankingCard;