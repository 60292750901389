import React from "react";
import Joyride from "react-joyride";
import FeatureWalkthrough from "../Dashboard/FeatureWalkthrough";
import walkthroughSteps from "../../data/walkthrough-steps.json";
import { useDispatch } from "react-redux";
import { storeWalkthroughRespone } from "../../redux/actions/video";

const customBeacon = () => null;
const GuidedTour = ({
  tourRun,
  closeTourRun,
  openSalestip,
  closeSalesTip,
  IsFeatureWalked,
}) => {
  const dispatch = useDispatch();
  const customiseTour = (data) => {
    const { type, index } = data;
    if (type === "step:after" && index === 6) {
      document.querySelector("#help_center").click();
      const tooltip = document.querySelector(".__floater");
      if (tooltip) {
        requestAnimationFrame(() => {
          tooltip.style.setProperty("top", "140px", "important");
        });
      }
    }

    if (index === 7) {
      const spotlight = document.querySelector(".react-joyride__spotlight");
      const tooltip = document.querySelector(".__floater");
      if (tooltip && spotlight) {
        spotlight.style.setProperty("width", "228px", "important");
        spotlight.style.setProperty("height", "178px", "important");
        const help_center_dropdown = document.querySelector("#help_center");
        const help_center_dropdown_pos =
          help_center_dropdown.getBoundingClientRect();
        const roundedX = Math.round(help_center_dropdown_pos.x);
        spotlight.style.setProperty("left", `${roundedX - 76}px`, "important");
        requestAnimationFrame(() => {
          setTimeout(() => {
            tooltip.style.setProperty("left", "-65px", "important");
          }, 400);
        });
      }
    }

    if (
      ((data.status === "finished" || data.status === "skipped") &&
        data.type === "tour:end") ||
      data.action === "close"
    ) {
      closeTourRun();
      if (typeof closeSalesTip === "function") {
        closeSalesTip(3);
      }
      if (data.action !== "close") {
        dispatch(storeWalkthroughRespone(true));
        if (typeof closeSalesTip === "function") {
          closeSalesTip(0);
        }
      }
      if (typeof openSalestip === "function") {
        openSalestip(3);
      }

      const menuElement = document.querySelector(".MuiMenu-paper");
      if (menuElement) {
        menuElement.style.display = "none";
      }
      if (IsFeatureWalked === false) {
        window.handleSalesTipGlobal();
      }
    }
    const finishedStatuses = ["finished", "skipped"];
    if (finishedStatuses.includes(data.status)) {
      document.querySelector("#header").classList.remove("staticPos");
      document.querySelector("#elanAlertWrapper").classList.remove("noTopPad");
    } else {
      document.querySelector("#header").classList.add("staticPos");
      document.querySelector("#elanAlertWrapper").classList.add("noTopPad");
    }
  };

  return (
    <Joyride
      steps={walkthroughSteps.steps}
      run={tourRun}
      continuous={true}
      beaconComponent={customBeacon}
      showProgress={true}
      showSkipButton={true}
      isFixed={true}
      disableBeacon={true}
      disableScrolling={false}
      tooltipComponent={FeatureWalkthrough}
      styles={{
        options: {
          zIndex: 9999,
        },
      }}
      callback={customiseTour}
    />
  );
};

export default GuidedTour;
