import Axios from "axios";
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

export const getSalesTips = async ({ featureKey }) => {

    const accessToken = await getAccessToken();
    try {
        const response = await Axios({
            url: `${config.base_url}/api/getSalesTipData`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                FeatureKeyId: featureKey
            })
        })
        const responseBody = await response.data;
        return {
            error: null,
            data: responseBody.Data
        }
    } catch (error) {
        return {
            error: (error.response && error.response.data.error) || 'Something went wrong!',
            data: []
        }
    }
}