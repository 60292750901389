import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  memo,
  lazy,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Card, CardContent } from "@material-ui/core";
import IframeComponent from "./IframeComponent";
import { useDispatch, useSelector } from "react-redux";
import { storeWalkthroughRespone } from "../../../redux/actions/video";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    height: "100%",
    width: "100%",
    outline: "none",
    borderRadius: 0,
  },
  card: {
    width: "489.82px",
    display: "flex",
    flexDirection: "column",
    transition: "all 0.25s ease-in-out",
    position: "relative",
    height: "490px",
    borderRadius: 0,
    outline: "none",
  },
  closeIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  button: {
    backgroundColor: "#0558FF",
    color: "#FFFFFF",
    border:'2px solid #0558FF',
    fontFamily: "USBankCircular",
    '&:hover': {
      backgroundColor: '#FFF', // Background color on hover
      color: '#0558FF', // Optional: Change text color on hover
      border:'2px solid #0558FF'
    },
  },
  skipButton: {
    color: "#0558FF",
    fontFamily: "USBankCircular",
    marginTop: "5px",
    '&:hover': {
      textDecoration:'underline',
      background:'#FFF'
    },
    // borderRadius: "4px",
  },
  video: {
    // borderRadius: "13.31px",
    transition: "all 0.4s ease-in-out",
    position: "relative",
    // borderRadius: "13.31px",
    width: "452px",
    height: "277.27px",
    backgroundImage: `url(${require("~/assets/images/dashboard/walkthrough_video_thumbnail.png")})`,
    // backgroundColor: "#000",
    marginLeft: "3px",
    backgroundSize: "cover", // Ensures the image covers the entire area
    backgroundPosition: "center", // Centers the image
  },
  videoText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  overlayButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    cursor: "pointer",
    transition: "filter 0.3s ease",
    "&:hover": {
      filter: "brightness(0.7)",
    },
  },
}));

const Component = lazy(() => import("../../../components/GuidedTour/"));

function VideoModal(props) {
  const dispatch = useDispatch();
  const { clicked, togglevideo, isVideoWalked, isFeatureWalked } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const [walkThrough, setWalkThrough] = useState(false);
  const [featureWalkthrough, setFeatureWalkthrough] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [showPlayButton, setShowPlayButton] = React.useState(true);

  const vidKey = isVideoWalked;
  const featKey = isFeatureWalked;
  

  useEffect(() => {
    if (vidKey === false) {
      setTimeout(() => {
        handleParentOpen();
      }, 300);
    } else if (clicked) {
      setTimeout(() => {
        setOpenIframeModal(true);
      }, 300);
    }
  }, [clicked, vidKey]);

  const handleParentOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    if (vidKey === false) {
      setOpenIframeModal(false);
    } else {
      setOpenModal(false);
    }

    if (typeof togglevideo === "function") {
      togglevideo(false);
    }
  };

  const handleVideo = () => {
    setPlayVideo(true);
    setOpenModal(false); // Close the parent modal
    setOpenIframeModal(true); // Open the iframe modal
    setShowPlayButton(false);
    if(featKey){
      dispatch(storeWalkthroughRespone(true, true));
    }else{
      dispatch(storeWalkthroughRespone(false, true));
    }
    
  };

  const handleOpenWalkThrough = () => {
    setWalkThrough(true);
    setFeatureWalkthrough(true);
    setOpenModal(false);
  };
  const handleSkip = () => {
    dispatch(storeWalkthroughRespone(true, true));
    setOpenModal(false);
    window.handleSalesTipGlobal();
  };

  const handleCloseWalkThrough = () => {
    setWalkThrough(false);
    setFeatureWalkthrough(false);
    setOpenModal(false);
  };
  const toggleCloseVidComponent = () => {
    setOpenIframeModal(false);
    setOpenModal(true);
  };

  return (
    <div>
      {walkThrough && (
        <Suspense fallback={<div>Loading...</div>}>
          <Component
            tourRun={featureWalkthrough}
            closeTourRun={handleCloseWalkThrough}
            IsVideoWalked={vidKey}
            IsFeatureWalked={featKey}
            //onTourEnd={getFeatureWalkthroughStatus}
          />
        </Suspense>
      )}

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Card className={classes.card} style={{}}>
          <CardContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              outline: "none",
            }}
          >
            <div style={{ position: "relative" }}>
              <div id="vid" className={classes.video}></div>

              <img
                className={classes.overlayButton}
                onClick={handleVideo}
                src={require("~/assets/images/dashboard/play.png")}
                alt="Play Video"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                columnGap: 1,
              }}
            >
              <h2 className={classes.videoText}>
                Introducing the all new CA$HIN!
              </h2>
              <span className={classes.videoText}>
                Explore CA$HIN 2.0 and use it to earn more rewards than ever
              </span>
              <span className={classes.videoText}>before!</span>
              &nbsp;
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleOpenWalkThrough}
              >
                <b>Get Started</b>
              </Button>
              <Button
                variant="text"
                className={classes.skipButton}
                onClick={handleSkip}
              >
                <b>Skip</b>
              </Button>
            </div>
          </CardContent>
        </Card>
      </Modal>

      {openIframeModal && (
        <IframeComponent
          IframeModal={openIframeModal}
          handleCloseIframe={() => {
            setOpenIframeModal(false);
            setOpenModal(true);
          }}
          playVideo={playVideo}
          showIcon={showPlayButton}
          closeClick={toggleCloseVidComponent}
          IsVideoWalked={vidKey}
          IsFeatureWalked={featKey}
        />
      )}
    </div>
  );
}

export default memo(VideoModal);
