import React from "react";
import { connect } from "react-redux";
import { Paper, AppBar, Tabs, Tab, Box, Grid, Button } from "@material-ui/core";
import { TabPanel, a11yProps } from "~/components/TabPanel/index";
import PaymentPreferenceListView from "../ListView/index";
import TaxationView from "../TaxForm/index";

import "./styles.scss";

class TabView extends React.Component {

    state = {
        selectedTab: 0,
        fromSSNModal: false,
        totalRewards: "",
        ssn:"",
    };

    componentDidMount = () => {
        let fromSSNModal = this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.fromSSNModal;
        let ssn = this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.ssn;
        let totalRewards = this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.totalRewards;
        const selectW9Form = this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.selectW9Form;
        if(fromSSNModal || selectW9Form) {
            this.setState({selectedTab: 1, fromSSNModal, totalRewards, ssn: ssn})
        } else {
            this.setState({selectedTab: 0, fromSSNModal, totalRewards, ssn:ssn})
        }
    }

    render() {
        const { selectedTab} = this.state;
        const { user} = this.props;
       const handleChange = (event, newValue) => {
            this.setState({ selectedTab: newValue});
        };
    
        return (
            <Grid container justify="center" className="manageAccountWrapper">
                <Grid item xs={11} md={12}>
                    <Box my={5} justifyContent="center">
                        <AppBar position="static" className="tabHeader">
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleChange}
                                        aria-label="Manage Account"
                                    >
                                       <Tab label="Payment Method" {...a11yProps(0)} />
                                       <Tab label="W9 Form" {...a11yProps(1)} />
                                    </Tabs>
                                </Grid>
                                <Grid item md={12}>
                                    <div className="tabContent" >
                                        <TabPanel value={selectedTab} index={0} >
                                            <PaymentPreferenceListView rewardsList={this.props.rewardsList} history={this.props.history} location={this.props.location}/>
                                        </TabPanel>
                                        <TabPanel value={selectedTab} index={1}>
                                            <TaxationView history={this.props.history} location={this.props.location} totalReward={this.state.totalRewards} fromSSNModal={this.state.fromSSNModal} ssn={this.state.ssn}/>
                                        </TabPanel>
                                    </div>
                                </Grid>
                            </Grid>
                        </AppBar>

                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    uses: state.user
});

export default connect(mapStateToProps, {

})(TabView);
