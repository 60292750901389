import React, { useEffect, useState } from "react";
import './styles.scss';
import { IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { ExpandMoreSharp } from "@material-ui/icons";

const SelectDropdown = ({ selectList, selectedValue, onSelectChange ,style={},iconPlacementToRight='0px'}) => {
    const [selectedOption, setSelectedOption] = useState(selectedValue);
    useEffect(()=>{
        getSelectedItemText(selectedValue);
    },[selectedValue]);

    const handleOption = (event) => {
        setSelectedOption(event.target.value);
        onSelectChange(event.target.value);
    }

    const getSelectedItemText = (selected) => {
        if (selected === -1) {
            setSelectedOption(selected);
            return <Typography>Help Center</Typography>;
        }
        const selectedOptiontext = selectList.find(option => option.value === selectedOption);
        return selectedOptiontext ? selectedOptiontext.item : '';
    };

    return (
        <>
            <Select value={selectedOption} variant="outlined" className="leaderboard-select"
                style={style}
                IconComponent={(props) => (<IconButton
                    {...props}
                    size="small"
                    style={{ color:selectedOption===80? "#000000":"#FFF", right: iconPlacementToRight, top: 'calc(50% - 15px)' }}
                >
                    <ExpandMoreSharp />
                </IconButton>)}
                MenuProps={{
                    PaperProps: {
                        style: {
                            marginTop: '45px',
                            marginLeft: '-60px',
                            borderRadius:'0px',
                             color:"#084BB7"
                        },
                    },
                }}
                renderValue={getSelectedItemText}
                onChange={handleOption}>
                {selectList.map((option) => (
                    <MenuItem className="item-test" key={option.value} value={option.value}>
                        {option.icon && (
                            <img alt="icon" src={option.icon} style={{ padding: '5px' }} />
                        )}
                        {option.item}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}

export default SelectDropdown;