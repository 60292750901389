const initialState = {
  Data: {},
  error: null,
};

export default function Survey_Reducer(state = initialState, action = {}) {
  switch (action.type) {
    case "SUCCESS_FETCH_SURVEY_QUESTION_ANSWER":
      const newResponse = {
        ...state,
        Data: action.payload,
        error: null,
      };
      return newResponse;
    case "FAILED_FETCH_SURVEY_QUESTION_ANSWER":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case "SUCCESS_UPDATION_SURVEY_OPTION":
      const updateSurvey = {
        ...state,
        response: action.payload,
        error: null,
      };
      return updateSurvey;
    case "FAILED_UPDATION_SURVEY_OPTION":
      return {
        ...state,
        error: action.payload.error,
      };
    case "SUCCESS_FETCH_BANKER_RESPONSE":
      const newState = {
        ...state,
        surveyResponse: action.payload,
        error: null,
        loading:false
      };
      return newState;
    case "FAILED_FETCH_BANKER_RESPONSE":
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
}
