const initialState = {
    leaderboard: {
        allPromotions: null,
        leaderboardTableData: [],
        error: null
    }
}

export default function leaderboard(state = initialState, action = {}) {
    switch (action.type) {
        case 'FETCH_ALL_PROMOTIONS':
            return {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    allPromotions: action.payload.Data
                }
            };
        case 'FAILED_ALL_PROMOTIONS':
            return {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    error: action.payload,
                }
            };
        case 'FETCH_LEADERBOARD_DATA':
            return {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    leaderboardTableData: action.payload.Data
                }
            };
            case 'FAILED_LEADERBOARD_DATA':
                return {
                    ...state,
                    leaderboard: {
                        ...state.leaderboard,
                        error: action.payload
                    }
                };
        default:
            return {
                ...state
            }
    }
}