import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Cookies from 'universal-cookie';
import './styles.scss';
import TabView from "./TabView";

class AuthRoute extends React.Component {

    isAllowed() {
        let cookies = new Cookies(window.document.cookie);
        let am = cookies.get("am");
        return am && (am === "001" || am === "002");
    }

    render() {
        const { component: Component, ...rest } = this.props;
        let isAccessable = this.isAllowed();
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

export default class Bankers extends React.Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <AuthRoute exact path='/bankers' component={TabView} />
                    {/*<AuthRoute exact path='/bankers' component={ListView} /> */}
                </Switch>
            </Fragment>
        )
    }
}