import React, { Fragment } from "react";
import { MenuItem, TextField, Icon, InputAdornment, OutlinedInput, Grid, Paper, Box, Button, Collapse, CircularProgress, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell, TableSortLabel } from '@material-ui/core'
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import DateRangeIcon from '@material-ui/icons/DateRange';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { fetchReportsList } from "~/redux/actions/reports.js";
import { downloadBankerRewardList } from "~/redux/helpers/banker.js";
import { fetchDownloadPromotionReport } from '~/redux/helpers/reports';
import "./styles.scss";
import Cookies from "universal-cookie";
import moment from "moment-timezone";
import currency from 'currency.js';
import CurrencyInput from '~/components/CurrencyInput';
import { AlertDialog } from '~/components/Dialogs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';

class ReportsView extends React.Component {
	state = {
		loading: false,
		page: 0,
		detailPage: 0,
		rowsPerPage: 10,
		PortBu: 0,
		PromotionName: "",
		errorDialog: false,
		fetchingDetail: false,
		errorMsg: null,
        fromDate:null,
        toDate:null,
		showReport:false,
		previewProgress:false,
        downloadProgress:false,
		blankField: false,
		blankData: false,
		ToFromDateError: false,
		dateError: false,
		SortColumn: "",
		SortOrder: "",
	}

	// componentDidMount() {
	// 	//this.fetchData();
	// }

	handleToDate =(toDate) =>{
        this.setState({toDate:toDate});
    }

    handleFromDate =(fromDate) =>{
        this.setState({fromDate:fromDate});
    }

	handleError() {
		this.setState({ errorDialog: true, fetchingDetail: false });
	}

	fetchData(SortColumn, SortOrder) {
		const { accessToken, portbu, oeid } = this.props.user.info;
		const { fromDate, toDate, PromotionName, page, rowsPerPage } = this.state;

		this.setState({
			loading: true,
			ToFromDateError: false,
			blankField: false,
			dateError: false,
		}, () => {
			this.props.dispatch(fetchReportsList({ accessToken, PromotionName, PBU: portbu, OEID: oeid, toDate, fromDate, PageNo: page + 1, PageSize: rowsPerPage, SortColumn: SortColumn || "", SortOrder: SortOrder || "" })).then((res) => {
				this.setState({
					loading: false,
				})
				if (res === false) {
					this.setState({
						errorMsg: "No Records to found."
					});
					this.handleError();
				}
			}).catch(err => {
				this.handleError();
			})
		})
	}

	handlePageChange = (event, page) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page,
			loading: true
		}, () => this.fetchData(SortColumn, newSortOrder))
	}

	handleSorting(SortColumn) {
		const { SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
		this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, loading: true }, () => {
			this.fetchData(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
		});
	}

	validateForm = () => {
		const { fromDate, toDate, PromotionName } = this.state;
		let isValid = true;
		if((!PromotionName && !fromDate && !toDate)) {
            this.setState({
                showReport:false,
				blankField: true,
				ToFromDateError: false,
            });
			isValid = false;

            return false;
        } else {
			if((fromDate  && !toDate) || (!fromDate  && toDate)) {
				//show date error
				this.setState({
					showReport:false,
					dateError: true,
					blankField: false,
					ToFromDateError: false,
				});
				isValid = false;
				return false;
			} 
			if(fromDate && toDate) {
				if(!moment(toDate).isSameOrAfter(fromDate, "day")){
                     //show error message  
					 this.setState({
						showReport:false,
						dateError: true,
						blankField: false,
						ToFromDateError: false,
					});
					isValid = false;
					return false;
				}
			}

		}
		return isValid;
	}

	search = () => {
		const isValid = this.validateForm();
		if(isValid) {
			this.setState({
				//loading: true,
				showReport:true,
			}, () => this.fetchData())
		}
	}

	clear = () => {
		this.setState({
			//loading: true,
			page: 0,
			rowsPerPage: 10,
			fromDate: null,
			toDate: null,
			PromotionName: "",
			errorMsg: null,
			showReport: false,
		})
	}

	handleRowsPerPageChange = (event) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10),
			loading: true
		}, () => this.fetchData(SortColumn, newSortOrder))
	}

	handleDownload = (event, item) => {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileName = "Rewards Detail";
		const fileExtension = '.xlsx';

		const {items, fromDate, toDate, showReport} = this.state;

		this.setState(
			{ downloadProgress: true },
			() => {
				//download functionality
				const { accessToken, portbu, oeid } = this.props.user.info;
            	const { PromotionId } = item;

				fetchDownloadPromotionReport({ accessToken, PromotionId, PBU: portbu, OEID: oeid, IsDownload: true }).then((response) => {
					if (response.error) {
						//just return false in case of API though any error
						//error message
						this.setState({ downloadProgress: false });
						this.handleError();
						return false;
					}
					if (response.data.length) {
						const ws = XLSX.utils.json_to_sheet(response.data);
						const wb = { Sheets: { 'Rewards Detail': ws }, SheetNames: ['Rewards Detail'] };
						const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
						const data = new Blob([excelBuffer], { type: fileType });
						FileSaver.saveAs(data, fileName + fileExtension);

						this.setState({ downloadProgress: false });
					} else {
						//error message
						this.setState({
							downloadProgress: false,
							errorMsg: "No Records to download"
						});
						this.handleError();
					}
				})
			}
		);
	}

	

	render() {
		const { errorMsg, loading, page, rowsPerPage, PromotionName, fromDate, toDate, errorDialog, SortColumn, SortOrder, showReport, blankField, ToFromDateError, dateError } = this.state;
		const { user, reports } = this.props;
		return (
			<Fragment>
				<Grid container id="reports">
					<Grid item md={12}>
						<div className="reports-heading">
							Rewards Detail
						</div>
					</Grid>
					<Grid item xs={12}>
							<Grid container={true} hidden className="filterWrap">
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label
											for="inputxLabel9"
											id="fromDate"
											className="inputLabel">From Date</label>
										<DatePicker
											aria-labelledby="fromDate"
											id="inputxLabel9"
											customInput={<OutlinedInput
												variant="outlined"
												className="full-width"
												endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
											/>}
											inputProps={{
												ref: el => this.fromDate = el
											}}
											minDate={moment().subtract(365, 'day').toDate()}
											maxDate={new Date()}
											className="datePicdesign dateField"
											selected={fromDate || ""}
											// onChange={date => {
											// 	this.setState({
											// 		fromDate: new Date(moment(date).startOf('day')),
											// 	})
											// }}
											onChange={(fromDate) => this.handleFromDate(fromDate)}
											onChangeRaw={(e) => e.preventDefault()}
										/>
									</Box>
									{this.FromDateError && <span className="errorMessage">From Date</span>}
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label
											for="inputxLabel10"
											id="toDate"
											className="inputLabel">To Date</label>
										<DatePicker
											aria-labelledby="toDate"
											id="inputxLabel10"
											customInput={<OutlinedInput
												variant="outlined"
												className="full-width"
												endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
											/>}
											inputProps={{
												ref: el => this.toDate = el
											}}
											minDate={moment().subtract(365, 'day').toDate()}
											maxDate={new Date()}
											className="datePicdesign dateField"
											selected={toDate || ""}
											// onChange={date => {
											// 	this.setState({
											// 		toDate: new Date(moment(date).endOf('day')),
											// 	})
											// }}
											onChangeRaw={(e) => e.preventDefault()}
											onChange={(toDate) => this.handleToDate(toDate)}
										/>
									</Box>
									{this.ToDateError && <span className="errorMessage">Please select to date</span>}
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label
											for="inputxLabel8"
											id="promotionName"
											className="inputLabel">Promotion Name</label>
										<TextField
											aria-labelledby="promotionName"
											id="inputxLabel8"
											fullWidth={true}
											autoComplete="off"
											variant="outlined"
											value={PromotionName || ""}
											onChange={event => this.setState({ PromotionName: event.target.value })}
											inputProps={{
												ref: el => this.PromotionName = el
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
								<Box mx={1} display="flex" justifyContent="flex-end" alignItems="center">
										<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => this.clear()} variant="contained" color="secondary" className="clearBtn">Clear</Button> </Link>
										{loading ? (
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										) : (
											<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => this.search()} variant="contained" color="primary">Search</Button> </Link>
										)}
									</Box>
								</Grid>
								<Grid item xs={12} sm={12}>
								{ToFromDateError && <span className="errorMessage">To date should be same or greater than from date</span>}
								{blankField && <span className="errorMessage">Please select dates or Promtion Name</span>}
								{dateError && <span className="errorMessage">Please select valid date</span>}
								</Grid>
							</Grid>
					</Grid>
					
					<Grid item xs={12} style={{ overflowX: 'auto', marginBottom: '50px' }} >
						{loading ? (
									<TableRow>
										<TableCell colSpan={9}>
											<Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										</TableCell>
									</TableRow>
								) : <>
								{showReport && 
									<Table>
										<StyledTableHead>
											<TableRow>
												<StyledTableCell>
													<TableSortLabel
														active={SortColumn === "PromotionId"}
														direction={SortColumn === "PromotionId" ? SortOrder : 'asc'}
														onClick={() => this.handleSorting("PromotionId")}
													>
														Promotion Name
														{SortColumn === "PromotionId" ? (
															<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
																{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
															</span>
														) : null}
													</TableSortLabel>
												</StyledTableCell>
												<StyledTableCell>
													<TableSortLabel
														active={SortColumn === "RewardUploadedDate"}
														direction={SortColumn === "RewardUploadedDate" ? SortOrder : 'asc'}
														onClick={() => this.handleSorting("RewardUploadedDate")}
													>
														Rewards Upload Date
														{SortColumn === "RewardUploadedDate" ? (
															<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
																{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
															</span>
														) : null}
													</TableSortLabel>
												</StyledTableCell>
												<StyledTableCell>
													<TableSortLabel
														active={SortColumn === "PromotionName"}
														direction={SortColumn === "PromotionName" ? SortOrder : 'asc'}
														onClick={() => this.handleSorting("PromotionName")}
													>
														Status
														{SortColumn === "PromotionName" ? (
															<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
																{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
															</span>
														) : null}
													</TableSortLabel>
												</StyledTableCell>
												<StyledTableCell>
													<TableSortLabel
														active={SortColumn === "PromotionName"}
														direction={SortColumn === "PromotionName" ? SortOrder : 'asc'}
														onClick={() => this.handleSorting("PromotionName")}
													>
														Download
														{SortColumn === "PromotionName" ? (
															<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
																{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
															</span>
														) : null}
													</TableSortLabel>
												</StyledTableCell>
											</TableRow>
										</StyledTableHead>
										<TableBody>
											{loading ? (
												<TableRow>
													<TableCell colSpan={9}>
														<Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
													</TableCell>
												</TableRow>
											) : (
												reports.list && reports.list.map((item, index) => (
													<Fragment key={index}>
														<StyledTableRow style={{ cursor: 'default' }}>
															<StyledTableCell>{item.PromotionName}</StyledTableCell>
															<StyledTableCell>{item.RewardUploadedDate ? moment.utc(item.RewardUploadedDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
															<StyledTableCell>{item.Status}</StyledTableCell>
															<StyledTableCell><GetAppIcon color="primary" fontSize="large" onClick={(event) => this.handleDownload(event, item)}/></StyledTableCell>
														</StyledTableRow>
													</Fragment>
												))
											)}
											{}
										</TableBody>
										<StyledTableFooter>
											<TableRow>
												<TablePagination
													rowsPerPageOptions={[10, 25, 50, 100]}
													colSpan={4}
													count={reports.reportsTotalCount || 0}
													rowsPerPage={rowsPerPage}
													page={page}
													SelectProps={{
														inputProps: { 'aria-label': 'rows per page' },
														native: true,
													}}
													onChangePage={this.handlePageChange}
													onChangeRowsPerPage={this.handleRowsPerPageChange}
												/>
											</TableRow>
										</StyledTableFooter>
									</Table>
									}
									</>
						}
						
					</Grid>
					
				</Grid>
				{errorDialog && <AlertDialog title={"OOPS!"} message={errorMsg || "Something went wrong, We're working on getting this fixed as soon as we can."} onConfirm={() => { this.setState({ errorDialog: false }) }} />}
			</Fragment>
		)
	}
}

export default connect(state => ({ ...state.user, ...state.reports }))(ReportsView);