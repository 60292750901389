import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user';
import moment from "moment";

export const fetchReportsList = ({ 
    accessToken, 
    PromotionName, 
    PBU, 
    OEID, 
    toDate,
    fromDate,
    PageNo, 
    PageSize,
    SortColumn, 
    SortOrder
}) => async (dispatch) => {
        try {
            dispatch({
                type: "REPORTS_LIST_FETCH_SUCCESS",
            });
            //const accessToken = await getAccessToken()
            const response = await axios({
                url: `${config.base_url}/api/QaReports/GetFilteredQAReport`,
                //url: `https://o056mlitq8.execute-api.us-east-2.amazonaws.com/uat/GetFilteredQAReport`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${accessToken}`,
                },
                data: JSON.stringify({
                    PromotionName: PromotionName || "",
                    PBU: PBU,
                    OEID: OEID,
                    ToDate: toDate? moment(toDate).format("YYYY-MM-DD"): "",
                    FromDate: fromDate? moment(fromDate).format("YYYY-MM-DD"): "",
                    TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    PageNo: PageNo || 1,
                    PageSize: PageSize || 10,
                    SortColumn: SortColumn || "",
                    SortOrder : SortOrder || ""
                })
            })

            const responseBody = await response.data;
            if (!responseBody.error) {
                dispatch({
                    type: 'REPORTS_LIST_FETCH_SUCCESS',
                    payload: responseBody.Data,
                    //totalCount: responseBody.Data[0] ? responseBody.Data[0].TotalCount : 0,
                    totalCount: responseBody.Data.length || 0,
                })
                return true
            }
            dispatch({
                type: 'REPORTS_LIST_FETCH_FAILED',
                payload: responseBody.error || "Oops! Something went wrong."
            })
            return false
        } catch (error) {
            dispatch({
                type: 'REPORTS_LIST_FETCH_FAILED',
                payload: error.message || "An error has occured."
            })
            return false
        }
    }