import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

const cookies = new Cookies(window.document.cookie)
const getQueryVar = (key) => {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
	  let pair = vars[i].split("=");
	  if (decodeURIComponent(pair[0]) === key) {
		return decodeURIComponent(pair[1]);
	  }
	}
  };
export const userInfo = () => async (dispatch) => {
	try {
		const accessTokenval = await getAccessToken()
		if(!accessTokenval){
			let cookies = new Cookies(window.document.cookie)
			cookies.set('accessToken', getQueryVar("accessToken"), { path: '/' })
			cookies.set('refreshToken', getQueryVar("refreshToken"), { path: '/' })
			cookies.set('portbu', getQueryVar("portbu"), { path: '/' })
			cookies.set('oeid', getQueryVar("oeid"), { path: '/' })
			cookies.set('am', getQueryVar("am"), { path: '/' })
		}
		const accessToken= accessTokenval? accessTokenval : getQueryVar("accessToken");
		if (accessToken) {
			const response = await axios({
                url: `${config.base_url}/api/GetWelcomeName`,
				// url:`https://9omj8nro5d.execute-api.us-east-2.amazonaws.com/dev/oauth/GetWelcomeName`,
				//url: `https://99pjhup0s9.execute-api.us-east-2.amazonaws.com/dev/GetWelcomeName`,
				method: 'POST',
				data: JSON.stringify({
					"Pbu": cookies.get('portbu')?cookies.get('portbu'): getQueryVar("portbu"),
					"Oeid": cookies.get('oeid') ?cookies.get('oeid'):getQueryVar("oeid") ,
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if (!responseBody.error) {
			cookies.set('locationId', responseBody.LOC, { path: '/' })
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: {
						accessToken,
						userInfo: responseBody,
						portbu: cookies.get('portbu')? cookies.get('portbu'):getQueryVar("portbu"),
						oeid: cookies.get('oeid') ?cookies.get('oeid'): getQueryVar("oeid"),
						am: cookies.get('am') ?cookies.get('am') : getQueryVar("am"),
						refreshToken: cookies.get('refreshToken') ?cookies.get('refreshToken'): getQueryVar("refreshToken"),
						// locationId:responseBody.LOC,
					}
				})
				return true;
			}
			
		}
		return logout();
	} catch (error) {
        	cookies.remove('accessToken');
            cookies.remove('refreshToken');
            cookies.remove('portbu');
            cookies.remove('oeid');
            cookies.remove('am');
			cookies.remove('locationId');
			cookies.remove('mfaToken')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const clientInfo= () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/IsExcludedFromMfa`,
				 method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},
			   data: JSON.stringify({
				   ppbu: cookies.get('portbu') || "",
				   poeid: cookies.get('oeid') || "",
				   ploc: cookies.get('locationId') || "",
			   })
			 
			   })
			const responseBody = await response.data
			// const responseBody =  {
			// 	"Data": [
			// 		{
			// 			"IsExcludedFromMFA": 0
			// 		}
			// 	],
			// 	"error": null
			// };
		 if (!responseBody.error) {
			cookies.set('mfaToken', responseBody.Data[0].IsExcludedFromMFA, { path: '/' });
		}
         return {
             error: null,
             data: responseBody.Data
         }
	}} catch (error) {
        	cookies.remove('accessToken');
            cookies.remove('refreshToken');
            cookies.remove('portbu');
            cookies.remove('oeid');
            cookies.remove('am');
			cookies.remove('locationId');
			cookies.remove('mfaToken')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const processLogin = ({ accessToken, refreshToken, portbu, oeid, am }) => async (dispatch) => {
	try {
		let cookies = new Cookies(window.document.cookie)
		cookies.set('accessToken', accessToken, { path: '/' })
		cookies.set('refreshToken', refreshToken, { path: '/' })
		cookies.set('portbu', portbu, { path: '/' })
		cookies.set('oeid', oeid, { path: '/' })
		cookies.set('am', am, { path: '/' })
		dispatch({
			type: 'LOGIN_SUCCESS',
			payload: {
				accessToken,
				refreshToken,
				portbu,
				oeid,
				am
			}
		})
	} catch (error) {
		dispatch({
			type: 'LOGIN_FAILED',
			payload: {
				error: (error.response && error.response.data.error) || "An error has occured."
			}
		})
		return false
	}
}

export const setNewPassword = (credentials) => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		const response = await axios({
			url: `${config.base_url}/api/OAuth/oauth/updatePassword`,
			//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/updatePassword`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(credentials)
		})
		const responseBody = await response.data
		if (!responseBody.error) {
			dispatch({
				type: 'UPDATE_PASSWORD_SUCCESS',
				payload: {
					PasswordChangedDate: new Date()
				}
			})
		} else {
			dispatch({
				type: 'UPDATE_PASSWORD_FAILED',
				payload: responseBody.error || 'Oops! Something went wrong.'
			})
		}
	} catch (error) {
		dispatch({
			type: 'UPDATE_PASSWORD_FAILED',
			payload: (error.response && error.response.data.error) || "An error has occured."
		})
	}
}

export const logout = () => {
	let cookies = new Cookies(window.document.cookie)
	cookies.remove('accessToken');
	cookies.remove('refreshToken');
	cookies.remove('portbu');
    cookies.remove('oeid');
	cookies.remove('am');
	cookies.remove('locationId');
	cookies.remove('mfaToken')
	return {
		type: 'LOGOUT_SUCCESS',
		payload: {}
	}
}