import React from 'react';
import { Grid, Box, Dialog, DialogContent, Typography, Divider, IconButton, makeStyles } from '@material-ui/core';
import CalculatorSalesTips from './CalculatorSalesTips';
import { CalculatorInput } from './CalculatorInput';
import CloseIcon from '@material-ui/icons/Close';
import { InfoOutlined } from "@material-ui/icons";


const useStyles = makeStyles(() => ({
    paper: {
        maxWidth: '846px',
    },
}));

export function PersonalizeCalculator(props) {
    const classes = useStyles();
    const { openCalculator, closeCalculator } = props;

    const handleClose = () => {
        closeCalculator();
    }

    return (
        <Dialog
            open={openCalculator}
            onClose={handleClose}
            PaperProps={{
                className: classes.paper,
            }}
        >
            <IconButton aria-label="close" style={{ position: 'absolute', right: '10px', top: '10px', borderRadius:0, height:"25px", width:"25px", background:"#E6E6E6" }} onClick={handleClose}>
                <CloseIcon style={{color:"#000"}} />
            </IconButton>
            <DialogContent>
                <Grid container spacing={1} style={{height:"595px", width:"auto"}} >
                    <Grid item sm={12}>
                        <Grid container>
                            <Grid item sm={4}>
                                <CalculatorSalesTips />
                            </Grid>
                            <Grid item sm={8}>
                                <Box style={{ width: "100%", marginTop:"10px" }}>
                                    <Typography style={{ fontSize: "24px", fontWeight: "500" }}>Personal Earnings Calculator</Typography>
                                    <Typography style={{ fontSize: "14px", fontWeight: "400" }}>Adjust the sliders to see your potential earnings from personal and business credit card activation, as well as balance transfers completed and funded.</Typography>
                                    <Divider style={{ marginTop: '8px', marginBottom: "8px" }} />
                                    <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Reward Categories</Typography>
                                    <CalculatorInput />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Box style={{
                            background: '#E6E6E6', display: 'flex',
                            alignItems: 'center', padding: '2px'
                        }}>
                            <InfoOutlined fontSize="400px" style={{marginLeft:"8px", marginRight: '5px'}} />
                            <Typography style={{  fontSize:"14px", fontWeight:400}}>Incentive calculations are based on the following values - $25/Active Consumer, $75/Active Business, and $25/Funded Balance Transfer.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
