const initialState = {
    dashboard: {
        currentBalance:"",
        earnedYTD:"",
        error:null,
        rewardsProjection:null
    }
}

export default function dashboard(state = initialState, action = {}) {
    switch (action.type) {
        case 'FETCH_BANKERS_REWARDS_DETAILS':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    currentBalance: action.payload.AvailableBalanace,
                    earnedYTD: action.payload.TotalEarnedRewards
                }
            };
            case 'FAILED_BANKERS_REWARDS_DETAILS':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    error: action.payload,
                }
            };
            case 'FETCH_BANKERS_POTENTIAL_REWARDS_PROJECTION_DETAILS':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    rewardsProjection:{
                        data:action.payload
                    }
                }
            }
            case 'FAILED_BANKERS_POTENTIAL_REWARDS_PROJECTION_DETAILS':
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    rewardsProjection:{
                        error:action.payload
                    }
                }
            };
        default:
            return {
                ...state
            }
    }
}