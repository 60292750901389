import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'

const cookies = new Cookies(window.document.cookie);

export const GetAllPromotions = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken();
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/getAllPromotions`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},
				data: JSON.stringify({
					"Pbu": cookies.get('portbu')
				})
			})
			const responseBody = response.data;
			if (!responseBody.error) {
				dispatch({
					type: 'FETCH_ALL_PROMOTIONS',
					payload: responseBody
				})
				return true;
			}
		}
	} catch (error) {
		dispatch({
			type: 'FAILED_ALL_PROMOTIONS',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const GetLeaderboardData = ({promoId}) => async (dispatch) =>{
	try {
		const accessToken = await getAccessToken();
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/getLeaderboardData`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},
				data: JSON.stringify({
					"Pbu": cookies.get('portbu'),
					"Oeid": cookies.get('oeid'),
					"PromoID": promoId,
					"IsFIRanking": 1,
					"IsPromoRanking": 1
				}),
			});
			const responseBody = response.data;
			if (!responseBody.error) {
				dispatch({
					type: 'FETCH_LEADERBOARD_DATA',
					payload: responseBody
				})
				return true;
			}
		}
	} catch (error) {
		dispatch({
			type: 'FAILED_LEADERBOARD_DATA',
			payload: error.message || "An error has occured."
		})
		return false
	}
}