const initialState = {
  videoStatus: {
    status: {},
    error: null,
  },
};

export default function Video_Reducer(state = initialState, action = {}) {
  switch (action.type) {
    case "SUCCESS_FETCH_FIRST_TIME_LOGIN":
      const newResponse = {
        ...state,
        videoStatus: {
          status: action.payload,
          error: null,
        },
      };
      return newResponse;
    case "FAILED_FETCH_FIRST_TIME_LOGIN":
      return {
        ...state,
        videoStatus: {
          loading: false,
          error: action.payload.error,
        },
      };
    case "SUCCESS_UPDATION_VIDEO_PLAY":
      const updateSurvey = {
        ...state,
        videoStatus: {
          status: action.payload,
          error: null,
        },
      };
      return updateSurvey;
    case "FAILED_UPDATION_VIDEO_PLAY":
      return {
        ...state,
        videoStatus: {
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}
