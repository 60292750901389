import React, { useState, useRef, useEffect } from "react";
import { ExpandMoreSharp, ExpandLessSharp } from "@material-ui/icons"; // Import icons from Material-UI
import color from "@material-ui/core/colors/amber";

const CustomDropdown = ({
  children,
  selectedOption,
  menuWidth = "200px", // Menu width
  menuHeight = "auto", // Menu height for top/bottom alignment
  menuPosition = {}, // Custom position object to define top, bottom, left, right
  className = "",
  id = "",
  style = {},
  buttonStyle = {}, // Custom dropdown button styling
  iconStyle = {}, // Combined prop for custom icon styling and positioning
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown open/closed
  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Effect to handle programmatic toggle via class or ID
  useEffect(() => {
    const handleProgrammaticToggle = (event) => {
      const target = event.target;
      if (
        (id && target.id === id) ||
        (className && target.classList.contains(className))
      ) {
        toggleDropdown();
      }
    };

    document.addEventListener("click", handleProgrammaticToggle);
    return () => {
      document.removeEventListener("click", handleProgrammaticToggle);
    };
  }, [id, className]);

  // Styling for the dropdown and its items
  const styles = {
    dropdown: {
      position: "relative",
      display: "inline-block",
      ...style,
    },
    toggle: {
      width: "100%",
      padding: "12px",
      backgroundColor: "#E5E5E5",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: "16px",
      position: "relative", // Ensure relative positioning for the icon to be positioned inside the button
      ...buttonStyle, // Apply custom button styling
    },
    label: {
      flex: 1,
      whiteSpace: "nowrap", // Prevent text wrapping
      overflow: "hidden", // Hide overflowed content
      textOverflow: "ellipsis", // Show ellipsis for long text
      textAlign: "left",
    },
    iconContainer: {
      position: "absolute", // Position the icon inside the button container
      pointerEvents: "none", // Ensure the icon doesn't interfere with clicking
      ...iconStyle, // Apply combined custom icon styling and positioning
    },
    menu: {
      position: "absolute",
      backgroundColor: "white",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: "1",
      padding: "0",
      margin: "0",
      listStyleType: "none",
      overflowY: "auto", // Allows scrolling if the content overflows
      width: menuWidth,
      maxHeight: menuHeight, // Use `maxHeight` for auto height
      transform: isOpen ? "scale(1)" : "scale(0.8)", // Apply zoom-in effect
      transformOrigin: "top", // Make it zoom from top
      display: isOpen ? "block" : "none", // Apply fade-in effect
      transition: "transform 0.1s ease-out, opacity 0.1s ease-out", // Smooth transition for zoom and fade
      ...menuPosition, // Spread custom position properties (left, right, top, bottom)
    },
  };

  return (
    <div
      ref={dropdownRef}
      style={styles.dropdown}
      className={className}
      id={id}
    >
      {/* Dropdown toggle button */}
      <button style={styles.toggle} onClick={toggleDropdown}>
        <span style={styles.label}>{selectedOption.item}</span>{" "}
        {/* Apply ellipsis to the label */}
        <span style={styles.iconContainer}>
          {isOpen ? <ExpandLessSharp /> : <ExpandMoreSharp />}{" "}
          {/* Toggle between open and close icons */}
        </span>
      </button>

      {/* Dropdown menu */}
      <ul style={styles.menu}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: () => {
              if (child.props.onClick) child.props.onClick();
              setIsOpen(false); // Close dropdown on option select
            },
          })
        )}
      </ul>
    </div>
  );
};

export default CustomDropdown;
