import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '../../redux/helpers/user'


export const fetchCashInMessage = async (payload) => {
	var accessToken = await getAccessToken()
	try{
		const response = await axios({
			url: `${config.base_url}/api/Redeem/RedeemRewardsBanner`,
			//url: `https://24dybugp0c.execute-api.us-east-2.amazonaws.com/uat/RedeemRewardsBanner`,
			// Input: payload,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data : JSON.stringify(payload)
		})
		return await response.data
	}catch(error){
		return {
			error: (error.response && error.response.data.error) || 'Something went wrong!'
		}
	}
}