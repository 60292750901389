import { Divider, Grid, makeStyles, Slider, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../../SelectDropdown';
import { activationRates, calculationParameters, calculationParameterNames } from '../../../../data/PersonalEarningsCalculator';
import './styles.scss';

const useStyles = makeStyles({
    input: {
        textAlign: 'center',
        height: '2px',
    },
    root: {
        width: "72px",
        marginLeft: "10px",
        marginTop: '11px',
        fontWeight: 'bold',
    },
});

export function CalculatorInput() {

    const [consumerCardBooked, setConsumerCardBooked] = useState(3);
    const [businessCardBooked, setBusinessCardBooked] = useState(2);
    const [activationRatePercentage, setActivationRatePercentage] = useState(activationRates[0].value);
    const [numberOfActiveCard, setNumberOfActiveCard] = useState(4);
    const [balanceTransferBooked, setBalanceTransferBooked] = useState(numberOfActiveCard);
    const classes = useStyles();

    useEffect(() => {
        setNumberOfActiveCard(Math.floor((activationRatePercentage / 100) * (consumerCardBooked + businessCardBooked)));
    }, [consumerCardBooked, businessCardBooked, activationRatePercentage]);

    useEffect(() => {
        setBalanceTransferBooked(numberOfActiveCard);
    }, [numberOfActiveCard]);

    const handleParameterValueChange = (parameterName, newValue) => {
        switch (parameterName) {
            case calculationParameterNames.CONSUMER_CARD_BOOKED:
                setConsumerCardBooked(newValue);
                break;
            case calculationParameterNames.BUSINESS_CARD_BOOKED:
                setBusinessCardBooked(newValue);
                break;
            case calculationParameterNames.FUNDED_BALANCE_TRANFERS:
                setBalanceTransferBooked(newValue);
                break;
            default:
                console.error("Error occured.")
        }
    };

    const handleParameterValue = (parameterName) => {
        switch (parameterName) {
            case calculationParameterNames.CONSUMER_CARD_BOOKED:
                return consumerCardBooked;
            case calculationParameterNames.BUSINESS_CARD_BOOKED:
                return businessCardBooked;
            case calculationParameterNames.FUNDED_BALANCE_TRANFERS:
                return balanceTransferBooked;
            default:
                console.log("Error Occured.");
        }
    };

    const handleInputValues = (event, parameter) => {
        const newValue = event.target.value === '' ? 0 : Number(event.target.value);
        if (parameter === calculationParameterNames.FUNDED_BALANCE_TRANFERS) {
            const numberOfAciveCard = ((activationRatePercentage / 100) * (consumerCardBooked + businessCardBooked));
            if (newValue <= numberOfAciveCard) {
                handleParameterValueChange(parameter, newValue);
            }
        }
        else if (newValue >= 0 && newValue <= 1000) {
            handleParameterValueChange(parameter, newValue);
        }
    }

    const activationRateSelection = (value) => {
        setActivationRatePercentage(value);
    }

    const calculateTotalPotentialRewards = () => {
        let fundedBalanceTransfers = balanceTransferBooked;
        let numberOfCardBooked = consumerCardBooked + businessCardBooked;
        let totalBalanceTransfer = (activationRatePercentage/100) * numberOfCardBooked;
        if (fundedBalanceTransfers > totalBalanceTransfer) {
            fundedBalanceTransfers = totalBalanceTransfer;
        }
        const totalPotentialRewards = ((consumerCardBooked * 25) + (businessCardBooked * 75)) * (activationRatePercentage / 100) + (fundedBalanceTransfers * 25);
        return totalPotentialRewards;
    }

    return (
        <Grid container spacing={1} direction='row' style={{marginTop:'15px'}}>
            {calculationParameters.map((parameter, index) => {
                return (
                    <Grid item xs={12} style={{ display: "flex", display:'flex', alignItems:'center', marginTop:"5px" }}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography style={{ fontSize: '16px', fontWeight: 400 }}>{parameter}</Typography>
                            {parameter === calculationParameterNames.ACTIVATION_RATE ? <Typography style={{ fontSize: '14px', fontWeight: 400,}}>Select your Projected Card Activation Rate</Typography> :
                                <Slider
                                    style={{ color: '#084BB7' }}
                                    value={handleParameterValue(parameter)}
                                    onChange={(event, newValue) => handleParameterValueChange(parameter, newValue)}
                                    defaultValue={handleParameterValue(parameter)}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={parameter == calculationParameterNames.ACTIVATION_RATE ? 100 : parameter == calculationParameterNames.FUNDED_BALANCE_TRANFERS ? numberOfActiveCard: 1000}
                                    step={parameter == calculationParameterNames.ACTIVATION_RATE ? 5 : 1}
                                    valueLabelFormat={(value) => parameter == calculationParameterNames.ACTIVATION_RATE ? `${value}%` : value}
                                />}
                        </div>
                        {parameter === calculationParameterNames.ACTIVATION_RATE ?
                            <div className='activation-select'>
                                <SelectDropdown selectList={activationRates}
                                 style={{ fontSize: "16px", fontWeight: 500 ,backgroundColor:activationRatePercentage===80? "#E6E6E6":"#084BB7", color:activationRatePercentage===80? "#000":"#FFF"}}
                                  selectedValue={activationRatePercentage} onSelectChange={activationRateSelection} />
                            </div> :
                            <TextField variant="outlined" value={handleParameterValue(parameter)}
                                onChange={(event) => handleInputValues(event, parameter)}
                                style={{ fontSize: "16px", fontWeight: 600 ,}}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                    },
                                }}
                                className={classes.root} />}
                    </Grid>
                )
            })}
            <Grid container>
                <Divider style={{ width: '100%', marginTop:"20px" }} />
                <Grid item xs={12} style={{ display: 'flex', height: '72px' }}>
                    <div style={{ flexGrow: 1 , display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ fontSize: "30px", fontWeight: 600 }}>Total</Typography>
                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Typography style={{ fontSize: "32px", fontWeight: 600, color: "#084BB7", }}>${Math.round(calculateTotalPotentialRewards())}</Typography>
                        </div>
                        <div>
                            <Typography style={{ fontSize: "14px", fontWeight: 400 ,}}>POTENTIAL REWARDS</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>)
}