import React, { Component, Fragment } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";
//import NavData from '~/config/navigation.json'
import "./styles.scss";
import Cookies from "universal-cookie";

export default class NavBar extends Component {
  state = {
    leftMenu: [
      {
        url: "/dashboard",
        name: "Dashboard",
        items: [],
      },
      {
        url: "/redeem",
        name: "Redeem",
        items: [],
      },
      {
        url: "/payments",
        name: "Rewards",
        items: [],
      },
      {
        url: "/manage-account",
        name: "Manage Account",
        items: [],
      },
      {
        url: "/support",
        name: "Support",
        items: [],
      },
      {
        url: "/bankers",
        name: "Employee Rewards",
        items: [],
      },
      {
        url: "/reports",
        name: "Reports",
        items: [],
      },
    ],
  };

  showLink(navItem) {
    let cookies = new Cookies(window.document.cookie);
    let am = cookies.get("am");
    if (navItem && navItem.name === "Employee Rewards") {
      switch (am) {
        case "001":
          return true;
        case "002":
          return true;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  render() {
    const { leftMenu } = this.state;
    const { path } = this.props.match;
    const currentNavIndex = _.findIndex(leftMenu, (item) => item.url === path);
    const { isLoggedIn } = this.props;
    return (
      <Fragment>
        <div id="navbar">
          {isLoggedIn ? (
            <Tabs value={currentNavIndex} indicatorColor="primary">
              {leftMenu.map(
                (navItem, index) =>
                  this.showLink(navItem) && (
                    <div role="tab">
                      <Link to={navItem.url} key={index} role="tablist" aria-label={`Navigate to ${navItem.name}`}>
                        <Tab label={navItem.name} />
                      </Link>
                    </div>
                  )
              )}
            </Tabs>
          ) : null}
        </div>
      </Fragment>
    );
  }
}
