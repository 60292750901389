import React, {Component, Fragment} from 'react';
import { Box, Paper, Grid, Container } from '@material-ui/core';
import "./styles.scss";

class Redeemmessage extends Component{
    render(){
        return(
            <Fragment> 
                
                <Grid container justify="center">
					<Grid item md={5}>

                    <Box bgcolor={'#fff'} boxShadow={2} fontSize={22} color={'#000000'} p={2} textAlign={'center'} justifyContent="center">  
                            <img alt="success-alert" src={require('~/assets/images/error-alert.png')} width="70" height="70" />
                          
                            <Box className="alert-dialog-title"> Verification Incomplete</Box>
                            <Box className="redeem-message-OTP"> Please verify that your email address is correct in your Elan Client Resource Center User Profile and then attempt to redeem your rewards again. If you update your email address, please note that this takes 1-2 business days to be reflected in our system. Please attempt to redeem your rewards again after that time. 
                            </Box> 
                            <Box className="redeem-message-OTP">
                            If you have a question about the CA$H IN redemption process, please contact Elan Client Services at 800-523-5354, option 4, or via email at clientservices@elanfs.com.</Box>
                    </Box> 
                 
                 </Grid>
                 </Grid>
            </Fragment>            
        )
    }
}

export default Redeemmessage;