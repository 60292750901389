import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AlertDialog } from "~/components/Dialogs";
import { checkSSNExistance } from "../../../redux/actions/redeem";
import { Box, TextField, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SSNAlertComponent = (props) => {
  const { openSSNAlert, oeid, portbu, accessToken, totalRewards } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const redeemSelector = useSelector((state) => state.redeem);

  const { SSNData } = redeemSelector.redeem;
  const [ssnAlert, setSsnAlert] = useState();
  const [oeids, setOeids] = useState();
  const [ssn, setSsn] = useState({ one: "", two: "", three: "", value: "" });
  const [openMergeW9Form, setOpenMergeW9Form] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [showMergeSuccessPopup, setShowMergeSuccessPopup] = useState(false);
  const ssn1Ref = useRef(null);
  const ssn2Ref = useRef(null);
  const ssn3Ref = useRef(null);
  useEffect(() => {
    dispatch(
      checkSSNExistance({
        PBU: portbu,
        OEID: oeid,
        SSN: ssn.value,
        accessToken,
      })
    );
  }, []);

  const handleSSNChange = (event, position) => {
    const newValue = event.target.value.trim();
    if (isNaN(newValue)) {
      return;
    }
    const updatedSSN = { ...ssn };

    switch (position) {
      case 1:
        updatedSSN.one = newValue || "";
        if (newValue.length === 3) {
          ssn2Ref.current.focus();
        }
        break;
      case 2:
        updatedSSN.two = newValue || "";
        if (newValue.length === 2) {
          ssn3Ref.current.focus();
        }
        break;
      case 3:
        updatedSSN.three = newValue || "";
        break;
      default:
        break;
    }

    const ssnValue = `${updatedSSN.one}${updatedSSN.two}${updatedSSN.three}`;
    updatedSSN.value = ssnValue;
    if (updatedSSN.value.length == 9) {
      dispatch(
        checkSSNExistance({
          PBU: portbu,
          OEID: oeid,
          SSN: updatedSSN.value,
          accessToken,
        })
      ).then((res) => {
        if (!res) {
          setErrorDialog(true);
          setSsnAlert(false);
        }
      });
    }
    setSsn(updatedSSN);
  };

  const handleDownload = () => {};

  const closeSSNAlert = () => {
    openSSNAlert(false);
  };
  const handleOpenMergeW9Form = () => {
    setOpenMergeW9Form(true);
    setSsnAlert(false);
  };
  const closeMergeW9Form = () => {
    setOpenMergeW9Form(false);
  };
  const openMergeProfileSuccess = () => {
    setOpenMergeW9Form(false);
    setShowMergeSuccessPopup(true);
  };

  // const mergeProfile = () => {
  //   dispatch(
  //     mergeProfile({ PBU: portbu, OEID: oeid, SSN: ssn.value, accessToken })
  //   ).then((response) => {
  //     if (!response) {
  //       closeMergeW9Form();
  //     }
  //     openMergeProfileSuccess();
  //     //call to check w9form exist or not again
  //     //checkW9FormExist();
  //   });
  // };
  const hideAlertMessage = () => {
    setShowMergeSuccessPopup(false);
    setOpenMergeW9Form(false);
    closeSSNAlert();
  };
  const returnMergePopup = (title, message) => {
    return (
      <AlertDialog
        title={title}
        message={message}
        showCancelBtn={true}
        clickOutsideToClose={() => closeMergeW9Form()}
        outsideClick={true}
        success={true}
        confirmText={"Confirm"}
        onConfirm={() => setShowMergeSuccessPopup(true)}
      />
    );
  };
  const renderAlertMessage = (title, message) => {
    return (
      <AlertDialog
        success={true}
        title={title}
        message={message}
        onConfirm={() => hideAlertMessage()}
      />
    );
  };

  const checkSsnExistance = () => {
    if (SSNData) {
      setSsnAlert(false);
      setOeids(SSNData.OEID || "");
      handleOpenMergeW9Form();
    } else {
      history.push("/manage-account", {
        fromSSNModal: true,
        totalRewards: totalRewards,
        ssn: ssn,
      });
    }
  };

  return (
    <>
      <AlertDialog
        dialogClassName="ssn-box"
        onClose={closeSSNAlert}
        clickOutsideToClose={false}
        success={false}
        title=""
        confirmText="Continue"
        px={10}
        py={4}
        btnDisabled={ssn.value.length !== 9}
        onConfirm={checkSsnExistance}
      >
        <Box
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
        >
          <div className="alert-dialog-title">Note</div>
          <div className="content">
            {`Elan requires IRS Form W-9 on an annual basis. The CA$H IN system does not have record of a ${new Date().getUTCFullYear()} W-9 tied to this Officer/Employee Identification (OEID). To redeem your rewards, please continue and complete IRS Form W-9.`}
            <div
              className="content"
              style={{ marginTop: "6px", fontWeight: "bold" }}
            >
              To begin Form W-9, please enter your Social Security Number.
            </div>
            <div
              className="SSNinputWrapper"
              style={{ display: "block", textAlign: "center" }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} md={4}>
                  <Box mx={1}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      value={ssn.one}
                      placeholder="###"
                      inputProps={{ ref: ssn1Ref, maxLength: 3 }}
                      onChange={(e) => handleSSNChange(e, 1)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box mx={1}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      value={ssn.two}
                      placeholder="##"
                      inputProps={{ ref: ssn2Ref, maxLength: 2 }}
                      onChange={(e) => handleSSNChange(e, 2)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box mx={1}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      aria-required="true"
                      inputProps={{ ref: ssn3Ref, maxLength: 4 }}
                      value={ssn.three}
                      placeholder="####"
                      onChange={(e) => handleSSNChange(e, 3)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Link
                    to="#"
                    onClick={handleDownload}
                    className="alert-dialog-link"
                  >
                    Why am I seeing this?
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </AlertDialog>
      {openMergeW9Form &&
        returnMergePopup(
          `Your W9 Form already exists with the OEID ${oeids}`,
          `You do not need to submit another W9 form and your profile of OEID ${oeid} and ${oeids} will be merged from here on. For any questions/concerns reach out to support contact.`
        )}
      {errorDialog && (
        <AlertDialog
          title={"OOPS!"}
          message={
            "Something went wrong, We're working on getting this fixed as soon as we can."
          }
          onConfirm={() => setErrorDialog(false)}
        />
      )}
      {showMergeSuccessPopup &&
        renderAlertMessage("", `Your profile have been merged successfully.`)}
    </>
  );
};

export default React.memo(SSNAlertComponent);
