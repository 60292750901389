import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import App from '~/App'
import { Provider } from 'react-redux'
import { createBrowserHistory } from "history";
import store from '~/redux'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#0558FF'
		}
	},
	typography: {
		fontSize: 14,
	},
	overrides: {
		MuiInputBase: {
			root: {
				fontSize: '14px',
				fontFamily: 'USBankCircular',
			}
		},
		MuiTextField: {
			root: {
				'&:focus': {
					outline: 'none'
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderWidth: 1,
				},
			}
		},
		MuiButton: {
			root: {
				borderRadius: 0,
				fontFamily: 'USBankCircular',
				padding: '.25rem 1.5rem',
				textTransform: 'capitalize',
				boxShadow: 'none !important',
				fontSize: '14px',
				fontWeight: 'normal',
				'& disabled': {
					border: 'none !important',
				},
				'&$containedPrimary': {
					border: '2px solid #0558FF',
					backgroundColor: '#0558FF',
					color: '#fff'
				},
				'&$containedPrimary:hover': {
					backgroundColor: '#fff !important',
					boxShadow: 'none !important',
					border: '2px solid #0558FF',
					color: '#0558FF'
				},
				'&$containedSecondary': {
					border: '2px solid #e6e6e6',
					backgroundColor: '#e6e6e6',
					color: '#000'
				},
				'&$containedSecondary:hover': {
					backgroundColor: '#fff !important',
					boxShadow: 'none !important',
					border: '2px solid #000',
					color: '#000'
				},
			},
		},
		MuiDialog: {
			paperScrollPaper: {
				borderRadius: 0,
				// padding: '3.125rem 2.75rem'
			}
		},
		MuiDialogTitle: {
			root: {
				padding: 0
			}
		},
		MuiSvgIcon: {
			root: {
				cursor: 'pointer'
			}
		},
		PrivateNotchedOutline: {
			root: {
				borderRadius: 0,
			}
		},
		MuiTableFooter: {
			root: {
				color: '#000 !important',
				opacity: 'inherit !important'
			}
		},
		MuiRadio: {
			root: {
				color: '#0558FF'
			}
		},
		MuiTablePagination: {
			root: {
				color: '#000',
				opacity: 'inherit'
			}
		},
		MuiTableCell: {
			head: {
				color: '#000',
				fontFamily: 'USBankCircular',
				fontWeight: 'bold',
				lineHeight: '1.2rem'
			},
			body: {
				color: '#000',
				fontFamily: 'USBankCircular',
			}
		},
		MuiTableSortLabel: {
			root: {
				color: '#000',
			}, 
			active: {
				color: '#000 !important',
			},
			icon: {
				color: '#000 !important',
			},
		},
		MuiTableHead: {
			root: {
				color: '#000 !important',
				opacity: 'inherit !important'
			}
		},
		MuiPaper: {
			root: {
				color: '#000'
			}
		},
		MuiTypography: {
			body1:{
				fontFamily: 'USBankCircular',
				fontSize: '14px',
				fontWeight: 'normal'
			},
			body2:{
				fontFamily: 'USBankCircular',
				fontSize: '14px',
				fontWeight: 'normal'
			},
			h1: {
				fontFamily: 'USBankCircular',
			},
			h2: {
				fontFamily: 'USBankCircular',
			},
			h3: {
				fontFamily: 'USBankCircular',
			},
			h4: {
				fontFamily: 'USBankCircular',
			},
			h5: {
				fontFamily: 'USBankCircular',
			},
			h6: {
				fontFamily: 'USBankCircular',
			},
		},
		MuiFormLabel: {
			root: {
				fontFamily: 'USBankCircular',
				fontSize: '14px',
				fontWeight: 'bold',
				color: '#000'
			}
		},
		MuiTab: {
			root: {
				fontFamily: 'USBankCircular',
				fontSize: '16px',
			}
		},
		MuiMenuItem: {
			root: {
				fontFamily: 'USBankCircular',
				fontSize: '14px',
			}
		}
	}
})

const history = createBrowserHistory();
ReactDOM.render((
	<MuiThemeProvider theme={theme}>
		<Provider store={store} >
			<App history={history}/>
		</Provider>
	</MuiThemeProvider>
), document.getElementById('root'))
