import Cookies from "universal-cookie";
import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers/user";

const cookies = new Cookies(window.document.cookie);
export const getSurveyQuestionAndAnswers = () => async (dispatch) => {
  try {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const response = await axios({
        url: `${config.base_url}/api/surveyQuestionAndAnswers`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
      });
      const responseBody = response.data;
      if (!responseBody.error) {
        dispatch({
          type: "SUCCESS_FETCH_SURVEY_QUESTION_ANSWER",
          payload: responseBody,
        });
        return true;
      }
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: "FAIL_FETCH_SURVEY_QUESTION_ANSWER",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};
export const storeBankerSuveryData =
  (optionId, questionId) => async (dispatch) => {
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        const response = await axios({
          url: `${config.base_url}/api/storeBankerSuveryData`,
          method: "POST",
          data: JSON.stringify({
            Pbu: cookies.get("portbu") || "",
            Oeid: cookies.get("oeid") || "",
            Questionid: questionId,
            Optionid: optionId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        });
        const responseBody = response.data;
        if (!responseBody.error) {
          dispatch({
            type: "SUCCESS_UPDATION_SURVEY_OPTION",
            payload: responseBody,
          });
          return true;
        }
      }
    } catch (error) {
      dispatch({
        type: "FAILED_UPDATION_SURVEY_OPTION",
        payload: error.message || "An error has occured.",
      });
      return false;
    }
  };
  export const getBankerSurveyResponse = (questionId) => async (dispatch) => {
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
       
        const response = await axios({
          url: `${config.base_url}/api/getBankerSurveyResponse`,
          method: "POST",
          data: JSON.stringify({
            Questionid: questionId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        });

        const responseBody = response.data;
        

        if (!responseBody.error) {
          dispatch({
            type: "SUCCESS_FETCH_BANKER_RESPONSE",
            payload: responseBody,

          });
          return true;
        }
      }
    } catch (error) {
      dispatch({
        type: "FAILED_FETCH_BANKER_RESPONSE",
        payload: error.message || "An error has occured.",
      });
      return false;
    }
  };
  
  
